import { createSlice } from '@reduxjs/toolkit';

interface AlertState {
    message: string | null;
    severity: 'info' | 'success' | 'warning' | 'error';
}

const initialState: AlertState = {
    message: null,
    severity: 'info',
};

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setAlert: (state, action) => {
            state.message = action.payload.message;
            state.severity = action.payload.severity;
        },
        clearAlert: (state) => {
            state.message = null;
        },
    },
});

export const { setAlert, clearAlert } = alertSlice.actions;

export default alertSlice.reducer;
