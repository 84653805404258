/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ViewData from './ViewData';
import {
    addFacilityServiceFees,
    addFacilityServices,
    addFacilityUnits,
    addFacilityUsers,
    getFacilityById,
    removeFacilityServices,
    removeFacilityUnits,
    removeFacilityUsers,
} from '../../features/slices/facilitySlice';
import { AppDispatch, RootState } from '../../features/store';
import { IService } from '../../features/types/services.types';
import { IUser } from '../../features/types/users.types';
import { facilityKeys } from '../../utils/constants';
import HCAccordion from '../Accordion';
import AddLabServicesForm from '../forms/AddLabServicesForm';
import HCInput from '../Input';
import MUltiSelectInput from '../MultiSelect';
import { getAllUnitsList } from '../../features/slices/unitsSlice';

interface IServiceFee {
    serviceSubArea: string;
    serviceName: string;
    feeAmount: string | number;
    id: number;
}

interface IUserOptionType {
    label: string;
    value: string;
    userId: string;
    facilityUserId: string;
    block: boolean;
    delete: boolean;
    userType: string | null;
    facilityId: number;
    createdDate: string;
    updatedDate: string | null;
    createdBy: string | null;
    updatedBy: string | null;
}

interface IServiceOptionType {
    label: string;
    value: number;
}

interface FacilityModalContentProps {
    closeModal?: () => void;
    isView: boolean;
}

const FacilityModalContent = ({
    // closeModal,
    isView,
}: FacilityModalContentProps) => {
    const { selectedFacility, loading } = useSelector(
        (state: RootState) => state.facility,
    );
    const { data: user } = useSelector((state: RootState) => state.auth);
    const { data: users } = useSelector((state: RootState) => state.user);
    const { unitsList } = useSelector((state: RootState) => state.units);

    const { servicesList: services } = useSelector(
        (state: RootState) => state.serviceMaster,
    );

    const [serviceFees, setServiceFees] = useState<IServiceFee[] | []>([]);

    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        const array1 = selectedFacility?.facilityServices || [];
        const array2 = selectedFacility?.serviceFees || [];
        let mergedArray = array1
            ?.filter((i) => !i?.isDelete)
            .map((obj1) => {
                const matchingObj = array2.find(
                    (obj2) => obj2.serviceSubArea === obj1.subServiceArea,
                );
                return {
                    serviceSubArea: obj1?.subServiceArea,
                    serviceName: obj1?.serviceName,
                    feeAmount: matchingObj?.feeAmount || 0,
                    id: obj1?.id,
                };
            });

        mergedArray = [
            ...mergedArray,
            ...array2.filter(
                (obj1) =>
                    !array1.some(
                        (obj2) => obj1.serviceSubArea === obj2.subServiceArea,
                    ),
            ),
        ];
        mergedArray = mergedArray
            .filter((i) => i !== undefined)
            .map((i) => ({
                serviceSubArea: i.serviceSubArea,
                serviceName: i.serviceName,
                feeAmount: i.feeAmount,
                id: i.id,
            }));
        setServiceFees(mergedArray);

        if (!unitsList.length) {
            dispatch(getAllUnitsList());
        }
    }, [selectedFacility]);

    const handleSelectedUsersChange = (newValues: IUserOptionType[]) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let lastOne: any = newValues.at(-1);
        const isExist = selectedFacility.facilityUsers?.find(
            (i) => i.userId === lastOne?.userId,
        );
        if (isExist && isExist.isDelete) {
            lastOne = isExist;
        }

        dispatch(
            addFacilityUsers([
                {
                    userId: lastOne?.userId,
                    facilityUserId: selectedFacility?.facilityProfile?.userId,
                    facilityId: selectedFacility?.facilityProfile?.id,
                    userType: lastOne?.userType,
                    createdBy: lastOne?.createdBy,
                },
            ]),
        ).then((res) => {
            if (res.payload?.responseCode === '200') {
                dispatch(
                    getFacilityById(selectedFacility?.facilityProfile?.userId),
                );
            }
        });
    };

    const handleSelectedUnitsChange = (newValues: any[]) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let lastOne: any = newValues.at(-1);
        const isExist = selectedFacility.facilityUnitAndUsers?.find(
            (i: any) => i.id === lastOne?.id,
        );
        if (isExist && isExist.isDelete) {
            lastOne = isExist;
        }

        dispatch(
            addFacilityUnits({
                id: 0,
                unitId: lastOne?.id,
                facilityUserId: selectedFacility?.facilityProfile?.userId,
                createdDate: new Date().toISOString(),
            }),
        ).then((res) => {
            if (res.payload?.responseCode === '200') {
                dispatch(
                    getFacilityById(selectedFacility?.facilityProfile?.userId),
                );
            }
        });
    };

    const handleSelectedServicesChange = (newValues: IServiceOptionType[]) => {
        const filteredValues = selectedFacility.facilityServices.map(
            (i) => i.id,
        );
        const payload = newValues
            .filter((i) => !filteredValues.includes(i.value))
            .map((i) => ({
                serviceId: i.value,
                facilityId: selectedFacility?.facilityProfile?.id,
                createdBy: user?.UserID,
            }));
        dispatch(addFacilityServices(payload)).then((res) => {
            if (res.payload?.responseCode === '200') {
                dispatch(
                    getFacilityById(selectedFacility?.facilityProfile?.userId),
                );
            }
        });
    };

    const onRemoveServices = (obj: IServiceOptionType) => {
        const payload = {
            facilityId: selectedFacility?.facilityProfile?.id,
            serviceId: obj.value,
            createdBy: user?.UserID,
        };
        dispatch(removeFacilityServices([payload])).then((res) => {
            if (res.payload?.responseCode === '200') {
                dispatch(
                    getFacilityById(selectedFacility?.facilityProfile?.userId),
                );
            }
        });
    };

    const onAddFees = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const arr = serviceFees.map((i) => {
            return {
                serviceId: i.id,
                feeAmount: Number(i.feeAmount),
                facilityId: selectedFacility?.facilityProfile?.id,
                createdBy: user?.UserID,
            };
        });
        if (arr.length) {
            dispatch(addFacilityServiceFees(arr)).then((res) => {
                if (res.payload?.responseCode === '200') {
                    dispatch(
                        getFacilityById(
                            selectedFacility?.facilityProfile?.userId,
                        ),
                    );
                }
            });
        }
    };

    const onChangeServiceFee = (value: string, id: number) => {
        const filteredValues = serviceFees.map((i) => {
            if (i.id === id) {
                i.feeAmount = value;
            }
            return i;
        });
        setServiceFees(filteredValues);
    };

    return (
        <div className="flex flex-col gap-1">
            {isView && (
                <HCAccordion title="Facility Information">
                    <ViewData
                        data={selectedFacility?.facilityProfile}
                        fields={facilityKeys}
                    />
                </HCAccordion>
            )}
            <HCAccordion title="Facility Users">
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                    }}
                >
                    <MUltiSelectInput<IUserOptionType>
                        options={users.map((i: IUser) => {
                            return {
                                label: `${i.firstName} ${i?.lastName || ''} (${i?.roleName})`,
                                value: i.userId,
                                userId: i.userId,
                                facilityUserId: i.userId,
                                delete: i.isDelete,
                                block: i.isBlocked,
                                userType: i?.roleName,
                                facilityId:
                                    selectedFacility?.facilityProfile?.id,
                                createdDate: i.createdDate,
                                updatedDate: i.updatedDate,
                                createdBy: i.createdBy,
                                updatedBy: i.updatedBy,
                            };
                        })}
                        getOptionLabel={(option) => option.label}
                        title={'Facility users'}
                        placeholder={'Users'}
                        selectedValues={selectedFacility?.facilityUsers
                            ?.filter((i) => !i?.isDelete)
                            .map((i) => ({
                                label: `${i?.firstName} ${i?.lastName || ''} (${i?.userType})`,
                                value: i.userId,
                                userId: i.userId,
                                facilityUserId: i.userId,
                                delete: i.isDelete,
                                block: i.isBlocked,
                                userType: user?.UserRole,
                                facilityId:
                                    selectedFacility?.facilityProfile?.id,
                                createdDate: i.createdDate,
                                updatedDate: i.updatedDate,
                                createdBy: i.createdBy,
                                updatedBy: i.updatedBy,
                            }))}
                        onSelectedValuesChange={handleSelectedUsersChange}
                        disabled={loading === 'pending'}
                        disableAll={isView}
                        handleDelete={(data) =>
                            dispatch(
                                removeFacilityUsers([
                                    {
                                        id: 0,
                                        userId: data?.userId,
                                        facilityUserId:
                                            selectedFacility?.facilityProfile
                                                ?.userId,
                                        facilityId:
                                            selectedFacility?.facilityProfile
                                                ?.id,
                                    },
                                ]),
                            ).then((res) => {
                                if (res.payload?.responseCode === '200') {
                                    dispatch(
                                        getFacilityById(
                                            selectedFacility?.facilityProfile
                                                ?.userId,
                                        ),
                                    );
                                }
                            })
                        }
                    />
                </Box>
            </HCAccordion>
            <HCAccordion title="Facility Units">
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                    }}
                >
                    <MUltiSelectInput<any>
                        options={unitsList.map((i: any) => {
                            return {
                                ...i,
                                label: `${i.unitName} (${i.headOfUnit})`,
                                value: i.id,
                            };
                        })}
                        getOptionLabel={(option) => option.label}
                        title={'Facility units'}
                        placeholder={'units'}
                        selectedValues={selectedFacility?.facilityUnitAndUsers
                            ?.filter((i: any) => !i?.isDelete)
                            .map((i: any) => ({
                                ...i,
                                label: `${i.unitName} (${i.userName})`,
                                value: i.unitId,
                            }))}
                        onSelectedValuesChange={handleSelectedUnitsChange}
                        disabled={loading === 'pending'}
                        disableAll={isView}
                        handleDelete={(data) => {
                            dispatch(removeFacilityUnits(data?.unitId)).then(
                                (res) => {
                                    if (res.payload?.responseCode === '200') {
                                        dispatch(
                                            getFacilityById(
                                                selectedFacility
                                                    ?.facilityProfile?.userId,
                                            ),
                                        );
                                    }
                                },
                            );
                        }}
                    />
                </Box>
            </HCAccordion>
            <HCAccordion title="Facility Services">
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 2,
                    }}
                >
                    <MUltiSelectInput<IServiceOptionType>
                        options={services?.map((i: IService) => {
                            return {
                                label: `${i.serviceSubArea} (${i.serviceArea})`,
                                value: i.id,
                                delete: i.isDelete,
                                block: i.isBlocked,
                            };
                        })}
                        title={'Facility services'}
                        placeholder={'Services'}
                        selectedValues={selectedFacility?.facilityServices
                            ?.filter((i) => !i?.isDelete)
                            .map((i) => ({
                                label: `${i.subServiceArea} (${i.serviceName})`,
                                value: i.id,
                            }))}
                        getOptionLabel={(option) => option.label}
                        onSelectedValuesChange={handleSelectedServicesChange}
                        handleDelete={onRemoveServices}
                        disabled={loading === 'pending'}
                        disableAll={isView}
                    />
                </Box>
            </HCAccordion>
            <HCAccordion title="Service fees">
                <Box
                    sx={{
                        display: 'flex',
                        gap: '8px',
                        flexDirection: 'column',
                    }}
                >
                    {serviceFees?.length > 0 ? (
                        <form onSubmit={onAddFees}>
                            <Grid container spacing={2}>
                                {serviceFees?.map((i) => (
                                    <Grid item md={6} xs={12} key={i.id}>
                                        <HCInput
                                            name={String(i.id)}
                                            placeholder="Fee amount"
                                            label={`${i?.serviceSubArea}-${i.serviceName}`}
                                            type={'number'}
                                            max={100000}
                                            min={0}
                                            required
                                            onChange={(e) =>
                                                onChangeServiceFee(
                                                    e.target.value,
                                                    i.id,
                                                )
                                            }
                                            value={i?.feeAmount}
                                            disabled={isView}
                                        />
                                    </Grid>
                                ))}
                            </Grid>

                            {!isView && (
                                <Button
                                    variant="contained"
                                    type="submit"
                                    style={{
                                        marginTop: '8px',
                                        float: 'right',
                                        width: 'fit-content',
                                    }}
                                    disabled={loading === 'pending'}
                                >
                                    Add Service fees
                                </Button>
                            )}
                        </form>
                    ) : (
                        'No fees available'
                    )}
                </Box>
            </HCAccordion>
            {selectedFacility?.facilityProfile.isLab ? (
                <HCAccordion title="Lab services">
                    <AddLabServicesForm edit={isView} />
                </HCAccordion>
            ) : null}
        </div>
    );
};

export default FacilityModalContent;
