export const roles = [
    'SystemAdministrator',
    // 'FacilityUser',
    'Nurse',
    'LabUser',
    'CHO',
    'Receptionist',
    'Doctor',
    'SuperAdmin',
    'PharmacyUser',
];

export const pobs = [
    { label: 'Bo', value: 'Bo' },
    { label: 'Bombali', value: 'Bombali' },
    { label: 'Bonthe', value: 'Bonthe' },
    { label: 'Falaba', value: 'Falaba' },
    { label: 'Kailahun', value: 'Kailahun' },
    { label: 'Kambia', value: 'Kambia' },
    { label: 'Karene', value: 'Karene' },
    { label: 'Kenema', value: 'Kenema' },
    { label: 'Koinadugu', value: 'Koinadugu' },
    { label: 'Kono', value: 'Kono' },
    { label: 'Moyamba', value: 'Moyamba' },
    { label: 'Port Loko', value: 'Port Loko' },
    { label: 'Pujehun', value: 'Pujehun' },
    { label: 'Tonkolili', value: 'Tonkolili' },
    { label: 'W. Urban', value: 'W. Urban' },
    { label: 'W. Rural', value: 'W. Rural' },
];

export const patientCategories = [
    { label: 'Infant (<5yrs)', value: 'Infant (<5yrs)' },
    { label: 'Child (6-18yrs)', value: 'Child (6-18yrs)' },
    { label: 'Adult (18-65yrs)', value: 'Adult (18-65yrs)' },
    { label: 'Aged (>65yrs)', value: 'Aged (>65yrs)' },
];

export const labOrderResultUsers = [
    'LabUser',
    'SuperAdmin',
    'SystemAdministrator',
    'FacilityUser',
];

export const userRoles = [
    'SystemAdministrator',
    'Nurse',
    'LabUser',
    'CHO',
    'Receptionist',
    'Doctor',
    'PharmacyUser',
];
export const userRolesSystemAdmin = [
    'Nurse',
    'LabUser',
    'CHO',
    'Receptionist',
    'Doctor',
    'PharmacyUser',
];

export const initialUserValues = {
    firstName: '',
    lastName: '',
    gender: '',
    email: '',
    address: '',
    mobileCodeId: 0,
    mobileNumber: '',
    eSigned: 'NA',
    isBlocked: false,
    roleName: '',
    isDelete: false,
};

export const initialFacilityValues = {
    name: '',
    address: '',
    district: '',
    email: '',
    category: '',
    noOfStaff_Male: 0,
    noOfStaff_Female: 0,
    eSigned: 'NA',
    mobileCodeId: 0,
    mobileNumber: '',
    roleName: 'FacilityUser',
};

export const userTableCols = [
    { id: 'firstName', label: 'First name' },
    { id: 'lastName', label: 'Last name' },
    { id: 'gender', label: 'Gender' },
    { id: 'email', label: 'Email' },
    { id: 'roleName', label: 'Role' },
    { id: 'createdDate', label: 'Created At' },
    { id: 'actions', label: 'Actions' },
];

export const unitsTableCols = [
    { id: 'unitName', label: 'Unit name' },
    { id: 'unitDescription', label: 'Unit Description' },
    { id: 'headOfUnit', label: 'Head Of Unit' },
    { id: 'createdDate', label: 'Created At' },
    { id: 'actions', label: 'Actions' },
];

export const facilityColumns = [
    { id: 'name', label: 'Name' },
    { id: 'district', label: 'District' },
    { id: 'address', label: 'Address' },
    { id: 'phone', label: 'Phone' },
    { id: 'email', label: 'Email' },
    { id: 'status', label: 'Status' },
    { id: 'createdDate', label: 'Created At' },
    { id: 'actions', label: 'Actions' },
];

export const Ic10Columns = [
    { id: 'code', label: 'Code' },
    { id: 'description', label: 'Description' },
    { id: 'createdDate', label: 'Created At' },
    // { id: 'actions', label: 'Actions' },
];

export const drugsColumns = [
    { id: 'drugName', label: 'Drug Name' },
    { id: 'brandName', label: 'Brand Name' },
    { id: 'createdDate', label: 'Created At' },
    // { id: 'actions', label: 'Actions' },
];

export const labDiagnosticColumns = [
    { id: 'labServiceArea', label: 'Lab Service Area' },
    { id: 'labServiceSubArea', label: 'Lab Service SubArea' },
    { id: 'createdDate', label: 'Created At' },
    // { id: 'actions', label: 'Actions' },
];

export const patientsColumns = [
    { id: 'emrNo', label: 'EMR Number' },
    { id: 'firstName', label: 'First name' },
    { id: 'middleName', label: 'Middle name' },
    { id: 'lastName', label: 'Last name' },
    { id: 'gender', label: 'Gender' },
    { id: 'facilityName', label: 'Facility name' },
    { id: 'createdDate', label: 'Created At' },
    { id: 'actions', label: 'Actions' },
];

export const patientVisitColumns = [
    { id: 'patientName', label: 'Patient name' },
    { id: 'facilityName', label: 'Facility name' },
    { id: 'unitName', label: 'Unit name' },
    { id: 'emrno', label: 'EMR No' },
    { id: 'age', label: 'Age' },
    { id: 'slewScore', label: 'Slew Color' },
    { id: 'status', label: 'Status' },
    { id: 'paymentStatus', label: 'Payment status' },
    { id: 'referredToUserId', label: 'Consultation type' },
    { id: 'createdDate', label: 'Created At' },
    { id: 'actions', label: 'Actions' },
];

export const FACILITY_ROLE_ID = '1ab6eb43-3a4f-4091-a008-0be2d445936c';

export const facilityCategories = [
    { label: 'Hospital', value: 'Hospital' },
    { label: 'Clinic', value: 'Clinic' },
    { label: 'Community Health Centre', value: 'Community Health Centre' },
];

export const consultationColumns = [
    { id: 'patientName', label: 'Patient name' },
    { id: 'facilityName', label: 'Facility name' },
    { id: 'labOrderTotalPrice', label: 'Lab order total price' },
    { id: 'followUpDate', label: 'Followup date' },
    { id: 'referredToUserId', label: 'Consultation type' },
    { id: 'createdDate', label: 'Created At' },
    { id: 'actions', label: 'Actions' },
];

export const serviceMasterColumns = [
    { id: 'serviceArea', label: 'Service Area' },
    { id: 'serviceSubArea', label: 'Service SubArea' },
    { id: 'createdDate', label: 'Created At' },
    // { id: 'actions', label: 'Actions' },
];

export const labOrdersColumns = [
    { id: 'patientName', label: 'Patient name' },
    { id: 'facilityName', label: 'Facility name' },
    { id: 'consultByName', label: 'Doctor name' },
    { id: 'labServiceArea', label: 'Lab service area' },
    { id: 'labServiceSubArea', label: 'Lab service sub area' },
    { id: 'labOrderType', label: 'Lab order type' },
    { id: 'fees', label: 'Fee' },
    { id: 'createdDate', label: 'Created At' },
    { id: 'actions', label: 'Actions' },
];

export const paymentReportsColumns = [
    { id: 'dateTime', label: 'Date and Time' },
    { id: 'patientName', label: 'Patient Name' },
    { id: 'emrNo', label: 'EMR No.' },
    { id: 'billAmount', label: 'Total Bill Amount' },
    { id: 'staffUser', label: 'Staff User' },
];

export const generalCaseLoadColumns = [
    { id: 'doctorName', label: 'Doctor Name' },
    { id: 'phoneNumber', label: 'Phone Number' },
    { id: 'periodCaseLoad', label: 'Period Case Load' },
    { id: 'monthlyChange', label: 'Monthly Change' },
    { id: 'ytdCaseLoad', label: 'YTD Case Load' },
];

export const individualCaseLoadColumns = [
    { id: 'date', label: 'Date' },
    { id: 'patientName', label: 'Patient Name' },
    { id: 'emrNo', label: 'EMR No.' },
    { id: 'timeAssigned', label: 'Time Assigned' },
    { id: 'assignedBy', label: 'Assigned By' },
];

export const patientRegistrationColumns = [
    { id: 'date', label: 'Date' },
    { id: 'count', label: 'Count' },
    { id: 'growthRate', label: 'Growth Rate %' },
];

export const triageAndVitalColumns = [
    { id: 'emrNo', label: 'EMR No' },
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
    { id: 'dob', label: 'DOB' },
    { id: 'gender', label: 'Gender' },
    { id: 'chiefComplaint', label: 'Chief Complaint' },
    { id: 'createdDate', label: 'Created Date' },
];

export const VandTKeys = [
    'createdDate',
    'chiefComplaint',
    'airwayObstruction',
    'patientBreathless',
    'burns',
    'activeSeizures',
    'cardiacArrest',
    'mobility',
    'rr',
    'facialBurns',
    'bloodGlucose',
    'slewScore',
    'oxygen',
    'height',
    'hr',
    'bp',
    'fever',
    'temperature',
    'avpu',
    'trauma',
    'weight',
    'malariaRDT',
    'comment',
];

export const patientKeys = [
    'unitName',
    'consultationByName',
    'facilityName',
    'patientName',
    'serviceFeesTotal',
    'paymentType',
    'paymentStatus',
    'status',
    'vitalTriage',
    'patientVisitServices',
    'patientVisitInfo',
    'patientTriageVitalinfo',
    'createdDate',
];

export const visitKeys = ['serviceArea', 'serviceFees', 'serviceSubArea'];

export const labOrderKeys = [
    'facilityName',
    'consultByName',
    'labOrderType',
    'labServiceArea',
    'labServiceSubArea',
    'patientVisitId',
    'status',
    'consultation',
    'createdDate',
    'updatedDate',
];

export const userViewFields = [
    'address',
    'createdDate',
    'eSigned',
    'email',
    'firstName',
    'gender',
    'lastName',
    'mobileCodeId',
    'mobileNumber',
    'roleName',
];

export const patientViewFields = [
    'firstName',
    'middleName',
    'lastName',
    'gender',
    'maritalStatus',
    'dob',
    'pob',
    'category',
    'motherFullName',
    'fatherFullName',
    'facilityId',
    'facilityName',
    // 'dateOfBirth',
    'age',
    'createdOn',
    'headShotPicture',
    'mobilePhoneNo1',
    'mobilePhoneNo2',
    'emailAddress',
    'socialSecurityNo',
    'nin',
    'emrNo',
    'nationality',
    'residentialAddress',
];

export const labOrderResultKeys = [
    'attachmentPath',
    'createdDate',
    'instruction',
    'labOrderName',
    'labOrderType',
];

export const facilityKeys = [
    'name',
    'address',
    'district',
    'phone',
    'email',
    'category',
    'noOfStaff_Male',
    'noOfStaff_Female',
    'createdDate',
    'status',
    'isLab',
];

export const genderOptions = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
];

export const confirmOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
];

export const maritalStatusOptions = [
    { value: 'Unmarried', label: 'Unmarried' },
    { value: 'Married', label: 'Married' },
];

export const reportTypes = [
    { label: 'Payment Report', value: 'Payment Report' },
    { label: 'General Case Load', value: 'General Case Load' },
    { label: 'Individual Case Load', value: 'Individual Case Load' },
    { label: 'Patient Registration', value: 'Patient Registration' },
    { label: 'Triage and Vital', value: 'Triage and Vital' },
];

export const paymentOptions = [
    { value: 'Cash', label: 'Cash' },
    { value: 'Gratis', label: 'Gratis' },
    { value: 'FHC Account', label: 'FHC Account' },
    { value: 'Insurance', label: 'Insurance' },
    { value: 'Follow-Up', label: 'Follow-Up' },
];
export const mobilityOptions = [
    { label: 'Walking', value: 'Walking' },
    { label: 'Rest', value: 'Rest' },
    { label: 'Wheel chair', value: 'Wheel chair' },
    { label: 'Stretcher', value: 'Stretcher' },
];

export const avpuOptions = [
    { label: 'Alert', value: 'Alert' },
    { label: 'Not Alert', value: 'Not Alert' },
];

export const malariaRDTOptions = [
    { label: 'Reactive', value: 'Reactive' },
    { label: 'Non Reactive', value: 'Non Reactive' },
];

export const referralOptions = [
    {
        label: 'Internal Referral',
        value: 'Internal Referral',
    },
    {
        label: 'External Referral',
        value: 'External Referral',
    },
];

export const drugFormOptions = [
    {
        label: 'Tabs',
        value: 'Tabs',
    },
    {
        label: 'Syrup',
        value: 'Syrup',
    },
    {
        label: 'Caps',
        value: 'Caps',
    },
    {
        label: 'Injections',
        value: 'Injections',
    },
];

export const slewColorOptions = [
    {
        label: 'Green',
        value: 'Green',
    },
    {
        label: 'Red',
        value: 'Red',
    },
    {
        label: 'Orange',
        value: 'Orange',
    },
    {
        label: 'Yellow',
        value: 'Yellow',
    },
];
