import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { TableSortArgs } from '../../components/TableV2';
import { PAGE_PROPS } from '../constants/common.constants';
import { LAB_ORDER_INITIAL_STATE } from '../constants/labOrders.constants';
import { ISaveLabOrderPayload } from '../types/LabOrders.types';

export const getAllLabOrders = createAsyncThunk(
    'labOrders/get',
    async (data: TableSortArgs) => {
        const response = await axios.post(
            '/LabOrder/PatientLabOrder/Search',
            data,
        );
        return response.data;
    },
);

export const getAllLabOrdersList = createAsyncThunk(
    'labOrders/getList',
    async () => {
        const response = await axios.get('/LabOrder/GetAll');
        return response.data;
    },
);

export const savePatientLabOrder = createAsyncThunk(
    'labOrders/save',
    async (data: ISaveLabOrderPayload) => {
        const response = await axios.post(
            '/LabOrder/PatientLabOrder/Save',
            data,
        );
        return response.data;
    },
);

export const getLabOrderResult = createAsyncThunk(
    'labOrders/result',
    async (id: string) => {
        const response = await axios.post(
            '/LabOrder/PatientLabOrderResult/' + id,
        );
        return response.data;
    },
);

export const labOrders = createSlice({
    name: 'labOrders',
    initialState: LAB_ORDER_INITIAL_STATE,
    reducers: {
        onLabOrderPageChange: (state, action) => {
            state.pageProps = action.payload;
        },
        onChangeLabOrderType: (state, action) => {
            state.labOrderType = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllLabOrders.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllLabOrders.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.data = action.payload?.result || PAGE_PROPS;
                state.error = null;
            })
            .addCase(getAllLabOrders.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getAllLabOrdersList.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllLabOrdersList.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.list = action.payload?.result || [];
                state.error = null;
            })
            .addCase(getAllLabOrdersList.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(savePatientLabOrder.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(savePatientLabOrder.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(savePatientLabOrder.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getLabOrderResult.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getLabOrderResult.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.selectedLabOrderResult = action.payload?.result;
                state.error = null;
            })
            .addCase(getLabOrderResult.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            });
    },
});

export const { onLabOrderPageChange, onChangeLabOrderType } = labOrders.actions;

export default labOrders.reducer;
