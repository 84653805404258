import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { TableSortArgs } from '../../components/TableV2';
import { PAGE_PROPS } from '../constants/common.constants';
import { LAB_DIAGNOSTIC_INITIAL_STATE } from '../constants/labDiagnosticSlice.constants';

export const getAllLabOrdersMaster = createAsyncThunk(
    'labDiagnostic/get',
    async (data: TableSortArgs) => {
        const response = await axios.post('/LabOrder/Search', data);
        return response.data;
    },
);

export const getAllLabOrdersMasterList = createAsyncThunk(
    'labDiagnostic/getList',
    async () => {
        const response = await axios.get('/LabOrder/GetAll');
        return response.data;
    },
);

export const labDiagnostic = createSlice({
    name: 'labDiagnostic',
    initialState: LAB_DIAGNOSTIC_INITIAL_STATE,
    reducers: {
        onLabDiagnosticPageChange: (state, action) => {
            state.pageProps = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllLabOrdersMaster.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllLabOrdersMaster.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.data = action.payload?.result || PAGE_PROPS;
                state.error = null;
            })
            .addCase(getAllLabOrdersMaster.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getAllLabOrdersMasterList.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllLabOrdersMasterList.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.labOrderMaterList = action.payload?.result || [];
                state.error = null;
            })
            .addCase(getAllLabOrdersMasterList.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            });
    },
});

export const { onLabDiagnosticPageChange } = labDiagnostic.actions;

export default labDiagnostic.reducer;
