import jwt_decode from 'jwt-decode';

import { IAuthApiState } from '../types/authSlice.types';
import { getCookie } from '../../utils/cookieUtils';

let decoded = {
    UserProfile: '',
};

try {
    decoded = jwt_decode(getCookie('session') ?? '');
} catch (error) { /* empty */ }

export const AUTH_INITIAL_STATE: IAuthApiState = {
    data: decoded?.UserProfile ? JSON.parse(decoded?.UserProfile) : {},
    loading: 'idle',
    error: null,
    eSign: '',
};
