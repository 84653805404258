import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { TableSortArgs } from '../../components/TableV2';
import { PAGE_PROPS } from '../constants/common.constants';
import { SERVICES_INITIAL_STATE } from '../constants/servicesSlices.constants';

export const getAllServices = createAsyncThunk(
    'services/get',
    async (data: TableSortArgs) => {
        const response = await axios.post('/Services/Search', data);
        return response.data;
    },
);

export const getServicesList = createAsyncThunk('services/getAll', async () => {
    const response = await axios.get('/Services/GetAll');
    return response.data;
});

export const serviceMasterSlice = createSlice({
    name: 'serviceMaster',
    initialState: SERVICES_INITIAL_STATE,
    reducers: {
        onServicePageChange: (state, action) => {
            state.pageProps = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllServices.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllServices.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.data = action.payload?.result || PAGE_PROPS;
                state.error = null;
            })
            .addCase(getAllServices.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getServicesList.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getServicesList.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.servicesList = action.payload?.result || [];
                state.error = null;
            })
            .addCase(getServicesList.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            });
    },
});

export const { onServicePageChange } = serviceMasterSlice.actions;

export default serviceMasterSlice.reducer;
