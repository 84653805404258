import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { TableSortArgs } from '../../components/TableV2';
import { PAGE_PROPS } from '../constants/common.constants';
import { PATIENT_INITIAL_STATE } from '../constants/patientsSlice.constants';
import {
    IPatientSavePayload,
    IPatientVisitSavePayload,
} from '../types/patientsSlice.types';

export const getAllPatients = createAsyncThunk(
    'patients/get',
    async (data: TableSortArgs) => {
        const response = await axios.post('/Patient/Search', data);
        return response.data;
    },
);

export const savePatient = createAsyncThunk(
    'patients/save',
    async (data: IPatientSavePayload) => {
        const response = await axios.post('/Patient/Save', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    },
);

export const updatePatient = createAsyncThunk(
    'patients/update',
    async (data: IPatientSavePayload) => {
        const response = await axios.post('/Patient/update', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        return response.data;
    },
);

export const getPatientById = createAsyncThunk(
    'patient/getByID',
    async (id: string) => {
        const response = await axios.post(`/Patient/GetProfileById/${id}`);
        return response.data;
    },
);

export const getPatientImage = createAsyncThunk(
    'patient/getByEMR',
    async (id: string) => {
        const response = await axios.post(
            `/Patient/GetProfilePictureByEMR?EMR=${id}`,
        );
        return response.data;
    },
);

export const createVisit = createAsyncThunk(
    'patient/createVisit',
    async (data: IPatientVisitSavePayload) => {
        const response = await axios.post(`/Patient/Visit/Save`, data);
        return response.data;
    },
);

export const patientsSlice = createSlice({
    name: 'patients',
    initialState: PATIENT_INITIAL_STATE,
    reducers: {
        onSelectPatient(state, action) {
            state.selectedPatient = action.payload;
        },
        onPatientsPageChange: (state, action) => {
            state.pageProps = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllPatients.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllPatients.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.data = action.payload?.result || PAGE_PROPS;
                state.error = null;
            })
            .addCase(getAllPatients.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(savePatient.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(savePatient.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(savePatient.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(updatePatient.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(updatePatient.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(updatePatient.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getPatientById.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getPatientById.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.selectedPatient = action.payload?.result;
                state.error = null;
            })
            .addCase(getPatientById.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getPatientImage.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getPatientImage.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.imageInfo = action.payload?.result;
                state.error = null;
            })
            .addCase(getPatientImage.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(createVisit.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(createVisit.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(createVisit.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            });
    },
});

export const { onSelectPatient, onPatientsPageChange } = patientsSlice.actions;

export default patientsSlice.reducer;
