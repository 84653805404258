/* eslint-disable @typescript-eslint/no-explicit-any */
import CancelIcon from '@mui/icons-material/Cancel';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import React from 'react';

interface MultiSelectInputProps<
    T = { label: string; value: string; delete?: boolean },
> {
    options: T[];
    selectedValues: T[];
    onSelectedValuesChange: (values: T[]) => void;
    title: string;
    placeholder: string;
    disabled: boolean;
    disableAll?: boolean;
    handleDelete: (value: T) => void;
    getOptionLabel: (option: T) => string;
    size?: 'small' | 'medium';
    required?: boolean;
}

export default function MultiSelectInput<T>({
    options,
    selectedValues,
    onSelectedValuesChange,
    title = 'Label',
    placeholder = 'Placeholder',
    disabled,
    handleDelete,
    disableAll,
    getOptionLabel,
    size = 'small',
}: MultiSelectInputProps<T>) {
    return (
        <Stack spacing={3} sx={{ width: '100%' }}>
            <Autocomplete
                multiple
                id="tags-outlined"
                options={options}
                getOptionLabel={getOptionLabel}
                value={selectedValues}
                onChange={(event, newValue) => {
                    onSelectedValuesChange(newValue);
                }}
                filterSelectedOptions
                clearIcon={false}
                disabled={disabled || disableAll}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={title}
                        placeholder={placeholder}
                        disabled={disabled || disableAll}
                    />
                )}
                size={size}
                renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <Chip
                            label={getOptionLabel(option)}
                            {...getTagProps({ index })}
                            onDelete={() => handleDelete(option)}
                            deleteIcon={<CancelIcon />}
                            color={'success'}
                            disabled={disabled || disableAll}
                        />
                    ))
                }
            />
        </Stack>
    );
}
