import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { TableSortArgs } from '../../components/TableV2';
import { PAGE_PROPS } from '../constants/common.constants';
import { DRUGS_INITIAL_STATE } from '../constants/drugsSilce.constants';
import { IDrug } from '../types/drugs.types';

export const getAllDrugs = createAsyncThunk(
    'drugs/get',
    async (data: TableSortArgs) => {
        const response = await axios.post('/Drugs/Search', data);
        return response.data;
    },
);

export const addDrug = createAsyncThunk(
    'drugs/add',
    async (data: Partial<IDrug>) => {
        const response = await axios.post('/Drugs/Save', data);
        return response.data;
    },
);

export const getAllDrugsList = createAsyncThunk('drugs/getList', async () => {
    const response = await axios.get('/Drugs/GetAll');
    return response.data;
});

export const drugsSlice = createSlice({
    name: 'drugs',
    initialState: DRUGS_INITIAL_STATE,
    reducers: {
        onDrugsPageChange: (state, action) => {
            state.pageProps = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllDrugs.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllDrugs.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.data = action.payload?.result || PAGE_PROPS;
                state.error = null;
            })
            .addCase(getAllDrugs.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getAllDrugsList.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllDrugsList.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.drugsList = action.payload?.result || [];
                state.error = null;
            })
            .addCase(getAllDrugsList.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(addDrug.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(addDrug.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(addDrug.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            });
    },
});

export const { onDrugsPageChange } = drugsSlice.actions;

export default drugsSlice.reducer;
