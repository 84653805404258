import { IConsultationApiState } from '../types/consultation.types';

export const CONSULTATION_INITIAL_STATE: IConsultationApiState = {
    data: {
        result: [],
        totalPages: 0,
        totalRecords: 0,
    },
    pageProps: {
        isDescending: false,
        pageNumber: 0,
        perPage: 0,
        searchText: '',
        sortingOn: '',
    },
    selectedConsultation: null,
    selectedConsultationView: null,
    consultationList: [],
    loading: 'idle',
    error: null,
    patientVisitHistory: [],
};
