import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { USER_INITIAL_STATE } from '../constants/userSlice.constants';
import { IUserSavePayload } from '../types/users.types';

export const createUser = createAsyncThunk(
    'user/create',
    async (requestData: IUserSavePayload) => {
        const response = await axios.post('/Auth/RegisterUser', requestData);
        return response.data;
    },
);

export const updateUser = createAsyncThunk(
    'user/update',
    async (requestData: IUserSavePayload) => {
        const response = await axios.post('/Auth/UpdateUser', requestData);
        return response.data;
    },
);

export const getAllUsers = createAsyncThunk('user/get', async () => {
    const response = await axios.get('/Auth/Users');
    return response.data;
});

export const getUserById = createAsyncThunk(
    'user/getByID',
    async (id: string) => {
        const response = await axios.get(`/Auth/GetUserById?Id=${id}`);
        return response.data;
    },
);

export const userSlice = createSlice({
    name: 'user',
    initialState: USER_INITIAL_STATE,
    reducers: {
        onUsersPageChange: (state, action) => {
            state.pageProps = action.payload;
        },
        onSelectUser: (state, action) => {
            state.selectedUser = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createUser.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(createUser.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(createUser.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(updateUser.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(updateUser.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getAllUsers.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.data = action.payload ?? [];
                state.error = null;
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getUserById.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getUserById.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.selectedUser = action.payload[0];
                state.error = null;
            })
            .addCase(getUserById.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            });
    },
});

export const { onUsersPageChange, onSelectUser } = userSlice.actions;

export default userSlice.reducer;
