/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControl, FormLabel } from '@mui/material';
import TextField from '@mui/material/TextField';
import React from 'react';

type HTMLInputTypeAttribute =
    | 'number'
    | 'search'
    | 'time'
    | 'image'
    | 'text'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'month'
    | 'password'
    | 'radio'
    | 'range';

interface HCInputProps {
    label: string;
    value: string | number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string; // Optional error message
    multiline?: boolean; // Enable multiline input
    maxLength?: number; // Max width, default to 255
    placeholder?: string;
    size?: 'medium' | 'small';
    name?: string;
    required?: boolean;
    type?: HTMLInputTypeAttribute;
    endAdornment?: any;
    minLength?: number;
    disabled?: boolean;
    min?: number;
    max?: number;
    step?: number;
    loading?: boolean;
}

const HCInput: React.FC<HCInputProps> = ({
    label,
    value,
    onChange,
    name,
    placeholder,
    error,
    multiline = false,
    maxLength = 255,
    size = 'small',
    required,
    type = 'text',
    endAdornment,
    minLength = 2,
    disabled = false,
    min = 0,
    max,
    step = 0.1,
    loading = false,
}) => {
    return (
        <FormControl className="w-full mb-2">
            <FormLabel htmlFor={name} className="text-black">
                {label} {required ? '*' : ''}
            </FormLabel>
            <TextField
                value={value}
                id={name}
                onChange={onChange}
                error={Boolean(error)}
                helperText={error}
                variant="outlined"
                fullWidth
                type={type}
                required={required}
                multiline={multiline}
                name={name}
                size={size}
                disabled={disabled || loading}
                InputProps={{
                    endAdornment: endAdornment,
                }}
                rows={multiline ? 6 : 1}
                inputProps={{
                    maxLength: maxLength,
                    minLength: minLength,
                    min: min,
                    max: max,
                    step,
                }}
                className="font-bolder text-black"
                placeholder={placeholder ?? label}
            />
        </FormControl>
    );
};

export default HCInput;
