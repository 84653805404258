import React, { lazy, Suspense } from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';

import MainLayout from '../layout/MainLayout';
import { getUserRoleByToken } from '../utils/commonFuns';
import { getCookie } from '../utils/cookieUtils';
import HCLoader from '../components/HCLoader';

const Users = lazy(() => import('../views/users'));
const ServiceMaster = lazy(() => import('../views/serviceMaster'));
const Reports = lazy(() => import('../views/Reports'));
const PatientVisitCreation = lazy(() => import('../views/patientVisit'));
const Patients = lazy(() => import('../views/patients'));
const NotFound = lazy(() => import('../views/NotFound'));
const LabOrders = lazy(() => import('../views/LabOrders'));
const LabDiagnosticMaster = lazy(() => import('../views/labDiagnosticMaster'));
const ICDCM10Master = lazy(() => import('../views/ICDCM10Master'));
const Facility = lazy(() => import('../views/facility'));
const DrugMaster = lazy(() => import('../views/drugMaster'));
const Dashboard = lazy(() => import('../views/dashboard'));
const Consultation = lazy(() => import('../views/consultation'));
const SignIn = lazy(() => import('../views/auth/SignIn'));
const ErrorBoundary = lazy(() => import('../components/ErrorBoundary'));
const PrivacyPolicy = lazy(() => import('../views/PrivacyPolicy'));
const Units = lazy(() => import('../views/units'));

const isLogin = getCookie('session') ?? '';
const role = getUserRoleByToken(isLogin);

export const router = createBrowserRouter([
    {
        path: '/',
        element: role ? (
            <ErrorBoundary>
                <MainLayout>
                    <Suspense fallback={<HCLoader />}>
                        <Outlet />
                    </Suspense>
                </MainLayout>
            </ErrorBoundary>
        ) : (
            <ErrorBoundary>
                <Suspense fallback={<HCLoader />}>
                    <SignIn />
                </Suspense>
            </ErrorBoundary>
        ),
        children: [
            {
                path: 'dashboard',
                element: <Dashboard />,
            },
            {
                path: 'facility',
                element: <Facility />,
            },
            {
                path: 'users',
                element: <Users />,
            },
            {
                path: 'units',
                element: <Units />,
            },
            {
                path: 'patients',
                element: <Patients />,
            },
            {
                path: '/patients-visit',
                element: <PatientVisitCreation />,
            },
            {
                path: 'consultation',
                element: <Consultation />,
            },
            {
                path: 'services',
                element: <ServiceMaster />,
            },
            {
                path: 'icd-10',
                element: <ICDCM10Master />,
            },
            {
                path: 'lab-diagnostic',
                element: <LabDiagnosticMaster />,
            },
            {
                path: 'lab-orders',
                element: <LabOrders />,
            },
            {
                path: 'drugs',
                element: <DrugMaster />,
            },
            {
                path: 'reports',
                element: <Reports />,
            },
        ],
    },
    {
        path: '/privacy-policy',
        element: <PrivacyPolicy />,
    },
    {
        path: '*',
        element: <NotFound />,
    },
]);
