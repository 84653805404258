import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';

interface AccordionProps {
    title: string;
    children: React.ReactNode;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    styles?: any;
    onChangeValue?: (state: boolean) => void
}

const HCAccordion: React.FC<AccordionProps> = ({ title, children, styles, onChangeValue }) => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = () => {
        setExpanded(!expanded);
        onChangeValue && onChangeValue(!expanded)
    };

    return (
        <Accordion
            expanded={expanded}
            onChange={handleChange}
            sx={{
                '&:after': {
                    backgroundColor: 'transparent !important',
                },
                '&:before': {
                    boxShadow: 'none !important',
                },
                ...styles,
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                    backgroundColor: '#e2e8f0',
                }}
            >
                <Typography variant="h6" sx={{ margin: '0px' }}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: '16px' }}>
                {children}
            </AccordionDetails>
        </Accordion>
    );
};

export default HCAccordion;
