/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Grid, capitalize } from '@mui/material';
import dayjs from 'dayjs';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getESign } from '../../features/slices/authSlice';
import { AppDispatch, RootState } from '../../features/store';
import {
    downloadFile,
    pdfFileCheck,
    splitCamelCase,
} from '../../utils/commonFuns';
import HCModal from '../Modal';

interface ViewDataPros {
    data: Record<string, any>;
    fields: string[];
    fileViewSize?: 'sm' | 'md' | 'lg' | 'xl';
    fileViewHeight?: string;
    dataLoading?: boolean;
}

const ViewData = ({
    data,
    fields,
    fileViewSize = 'sm',
    fileViewHeight = '50',
    dataLoading = false,
}: ViewDataPros) => {
    const { eSign, loading } = useSelector((state: RootState) => state.auth);
    const [viewFile, setViewFile] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>('N/A');

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (eSign?.trim() !== '' && eSign !== 'N/A') {
            setSelectedFile(
                eSign?.includes('data:image')
                    ? eSign
                    : `data:image/png;base64,${eSign}`,
            );
        }

        return () => {
            setSelectedFile('N/A');
        };
    }, [eSign]);

    const setFile = (fileUrl: string) => {
        if (!fileUrl?.trim()) {
            return;
        }
        if (fileUrl.includes('image')) {
            setSelectedFile(fileUrl);
        } else {
            fetch(fileUrl)
                .then((res) => res.blob())
                .then((res) => {
                    setSelectedFile(URL.createObjectURL(res));
                })
                .catch((err) => console.log(err));
        }
    };

    if (dataLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Grid container spacing={2} alignItems={'start'}>
            {viewFile && (
                <HCModal
                    onClose={() => {
                        setViewFile(false);
                        setSelectedFile('');
                    }}
                    isOpen={viewFile}
                    title="View File"
                    size={fileViewSize}
                >
                    <div>
                        {loading === 'pending' ? (
                            <div>Loading...</div>
                        ) : (
                            <>
                                {selectedFile !== 'N/A' &&
                                selectedFile?.trim() ? (
                                    <div className="flex items-center flex-col gap-4 justify-center">
                                        {selectedFile?.includes('image') ? (
                                            <img
                                                src={selectedFile}
                                                style={{
                                                    width: '100%',
                                                    height: `${fileViewHeight}vh`,
                                                    objectFit: 'contain',
                                                }}
                                                onError={() =>
                                                    setSelectedFile('N/A')
                                                }
                                            ></img>
                                        ) : (
                                            <object
                                                data={selectedFile}
                                                style={{
                                                    width: '100%',
                                                    height: `${fileViewHeight}vh`,
                                                    objectFit: 'contain',
                                                }}
                                            ></object>
                                        )}
                                        <Button
                                            variant="outlined"
                                            onClick={() =>
                                                downloadFile(
                                                    eSign || selectedFile,
                                                )
                                            }
                                        >
                                            Download
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="text-center">
                                        File Not Found!
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </HCModal>
            )}
            {Object.entries(data)?.map(([key, value], idx) => (
                <Fragment key={idx}>
                    {fields?.includes(key) && typeof value !== 'object' && (
                        <Grid item md={4} xs={12} sm={6} className="h-auto">
                            <h5 className="text-bold text-lg m-0 mb-1">
                                {splitCamelCase(
                                    capitalize(
                                        key === 'facialBurns'
                                            ? 'burns'
                                            : key === 'slewScore'
                                              ? 'slewColor'
                                              : key,
                                    ),
                                )}
                            </h5>
                            <p className="text-md m-0">
                                {String(value)?.includes('base64') ||
                                (key === 'attachmentPath' && value !== '') ||
                                (key === 'eSigned' &&
                                    (data?.roleName === 'Doctor' ||
                                        data?.roleName === 'CHO')) ? (
                                    <Button
                                        variant="outlined"
                                        onClick={() => {
                                            if (key === 'eSigned') {
                                                dispatch(
                                                    getESign(data?.userId),
                                                );
                                            } else {
                                                setFile(
                                                    key === 'attachmentPath'
                                                        ? pdfFileCheck(value)
                                                        : value,
                                                );
                                            }

                                            setViewFile(!viewFile);
                                        }}
                                    >
                                        View file
                                    </Button>
                                ) : (
                                    <>
                                        {String(key)
                                            .toLowerCase()
                                            .includes('date') ||
                                        String(key)
                                            .toLowerCase()
                                            .includes('dob')
                                            ? dayjs(value).format(
                                                  'DD-MM-YYYY HH:mm',
                                              )
                                            : typeof value === 'boolean'
                                              ? value
                                                  ? 'Yes'
                                                  : 'No'
                                              : value || 'NA'}
                                    </>
                                )}
                            </p>
                        </Grid>
                    )}
                </Fragment>
            ))}
        </Grid>
    );
};

export default ViewData;
