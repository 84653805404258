import { PatientsApiState } from '../types/patientsSlice.types';

export const PATIENT_INITIAL_DATA = {
    id: 0,
    facilityId: 0,
    facilityUserId: '',
    facilityName: '',
    dateOfBirth: '',
    age: '',
    createdOn: '',
    headShotPicture: null,
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    maritalStatus: '',
    dob: '',
    pob: '',
    category: '',
    motherFullName: '',
    fatherFullName: '',
    mobilePhoneNo1: '',
    mobilePhoneNo2: '',
    emailAddress: '',
    socialSecurityNo: '',
    nin: '',
    emrNo: '',
    isBlocked: false,
    createdDate: '',
    updatedDate: null,
    createdBy: '',
    updatedBy: null,
    isDelete: false,
};

export const PATIENT_INITIAL_STATE: PatientsApiState = {
    data: {
        list: [],
        totalPages: 0,
        totalRecords: 0,
    },
    pageProps: {
        isDescending: false,
        pageNumber: 0,
        perPage: 0,
        searchText: '',
        sortingOn: '',
    },
    loading: 'idle',
    error: null,
    selectedPatient: PATIENT_INITIAL_DATA,
    imageInfo: undefined,
};
