// GlobalAlert.tsx
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { clearAlert } from '../features/slices/alertSlice';
import { AppDispatch, RootState } from '../features/store';

const GlobalAlert: React.FC = () => {
    const { message, severity } = useSelector(
        (state: RootState) => state.alert,
    );
    const dispatch = useDispatch<AppDispatch>();

    const handleClose = () => {
        dispatch(clearAlert());
    };

    return (
        <Snackbar
            open={!!message}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert severity={severity} onClose={handleClose}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default GlobalAlert;
