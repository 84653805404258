import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Navbar from '../components/AppBar';
import Sidebar from '../components/Sidebar';
import { RootState } from '../features/store';
import { getRedirectRoute } from '../utils/commonFuns';

export default function MainLayout({
    children,
}: {
    children: React.ReactNode;
}) {
    const { data } = useSelector((state: RootState) => state.auth);

    const [open, setOpen] = useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const route = window.location.pathname;

    if (route === '/') {
        window.location.href = getRedirectRoute(data?.UserRole);
    }

    return (
        <Box sx={{ display: 'flex', maxHeight: '100vh' }}>
            <Navbar open={open} toggleDrawer={toggleDrawer} />
            <Sidebar open={open} toggleDrawer={toggleDrawer} />
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
                className="hc-main-container"
            >
                <Toolbar />
                <Container maxWidth="xl" sx={{ my: 3 }}>
                    {children}
                </Container>
            </Box>
        </Box>
    );
}
