import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { TableSortArgs } from '../../components/TableV2';
import { CONSULTATION_INITIAL_STATE } from '../constants/consultationSlice.constants';

export const getAllConsultationList = createAsyncThunk(
    'consultation/get',
    async (data: TableSortArgs) => {
        const { perPage, pageNumber, searchText } = data;
        const response = await axios.post(
            `/Consultation/GetListAll?pageNumber=${pageNumber}&pageSize=${perPage}&searchText=${searchText}`,
        );
        return response.data;
    },
);

export const saveConsultation = createAsyncThunk(
    'consultation/save',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: any) => {
        const response = await axios.post('/Consultation/Save', data);
        return response.data;
    },
);

export const sendConsultationEmail = createAsyncThunk(
    'consultation/sendEmail',
    async (id: string) => {
        const response = await axios.post(
            `/Consultation/SendPatientConsulatationEmail?VisitId=${id}`,
        );
        return response.data;
    },
);

export const getPatientConsultation = createAsyncThunk(
    'consultation/getPatient',
    async (id: string) => {
        const response = await axios.post(
            `/Consultation/GetPatientConsulatationById?Id=${id}`,
        );
        return response.data;
    },
);

export const getPatientConsultationView = createAsyncThunk(
    'consultation/getPatientView',
    async (id: string) => {
        const response = await axios.post(
            `/Consultation/GetPatientConsulatationById?Id=${id}`,
        );
        return response.data;
    },
);

export const getPatientVisitHistory = createAsyncThunk(
    'consultation/getPatientVisitHistory',
    async (id: string) => {
        const response = await axios.post(
            `/Consultation/GetPatientHistoryByVisitOrConsultId?Id=${id}`,
        );
        return response.data;
    },
);

export const updateConsultation = createAsyncThunk(
    'consultation/update',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: any) => {
        const response = await axios.post('/Consultation/Update', data);
        return response.data;
    },
);

export const downloadConsultationPDF = createAsyncThunk(
    'consultation/downloadConsultationPDF',
    async (id: string) => {
        const response = await axios.post(`/Consultation/DownloadPatientConsultation?VisitId=${id}`,);
        return response.data;
    },
);

export const consultationSlice = createSlice({
    name: 'consultation',
    initialState: CONSULTATION_INITIAL_STATE,
    reducers: {
        onConsultationPageChange: (state, action) => {
            state.pageProps = action.payload;
        },
        onSelectConsultation: (state, action) => {
            state.selectedConsultation = action.payload;
        },
        resetConsultation: (state, action) => {
            state.selectedConsultationView = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllConsultationList.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllConsultationList.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.data = action.payload?.result;
                state.error = null;
            })
            .addCase(getAllConsultationList.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(saveConsultation.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(saveConsultation.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(saveConsultation.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(updateConsultation.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(updateConsultation.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(updateConsultation.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(sendConsultationEmail.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(sendConsultationEmail.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(sendConsultationEmail.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getPatientConsultation.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getPatientConsultation.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.error = null;
                state.selectedConsultation =
                    action.payload?.result?.result[0] ?? [];
            })
            .addCase(getPatientConsultation.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getPatientConsultationView.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getPatientConsultationView.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.error = null;
                state.selectedConsultationView =
                    action.payload?.result?.result[0] ?? [];
            })
            .addCase(getPatientConsultationView.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getPatientVisitHistory.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getPatientVisitHistory.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.error = null;
                state.patientVisitHistory = action.payload?.result ?? [];
            })
            .addCase(getPatientVisitHistory.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(downloadConsultationPDF.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(downloadConsultationPDF.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(downloadConsultationPDF.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
    },
});

export const { onConsultationPageChange, onSelectConsultation, resetConsultation } = consultationSlice.actions;

export default consultationSlice.reducer;
