import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    useMediaQuery,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import sideLogo from '../assets/logoWeb.png';
import { RootState } from '../features/store';
import { routes } from '../routes/sidebarItems';

const drawerWidth: number = 240;

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.down('sm')]: {
                width: 0, // Set width to 0 for mobile view
            },
        }),
        ...(open && {
            [theme.breakpoints.down('sm')]: {
                position: 'fixed', // Add fixed position for small screens
                z: 10,
            },
            [theme.breakpoints.up('sm')]: {
                position: 'relative', // Add fixed position for small screens
                z: 0,
            },
        }),
    },
}));

interface ISidebarProps {
    open: boolean;
    toggleDrawer: () => void;
}

const Sidebar = ({ open, toggleDrawer }: ISidebarProps) => {
    const [path, setPath] = useState('');
    const { data } = useSelector((state: RootState) => state.auth);

    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        const locationPath = window.location.pathname;
        if (locationPath) {
            setPath(locationPath);
        }
    }, []);

    return (
        <Drawer
            variant="permanent"
            open={open}
            sx={{ display: 'flex', alignItems: 'center' }}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-between',
                    px: [1],
                    position: 'relative',
                    borderBottom: '1px solid #e2e8f0',
                }}
            >
                <div className="flex items-center justify-center w-full transition-all">
                    <img
                        src={sideLogo}
                        alt="Health connect"
                        className="w-24 h-12"
                    />
                </div>
            </Toolbar>
            <List component="nav" className="hc-sidebar">
                {routes
                    .filter((i) => i.permission.includes(data?.UserRole))
                    .map((i, idx) => (
                        <Link
                            key={idx}
                            to={i.path}
                            className={`no-underline text-black`}
                            onClick={() => {
                                setPath(i.path);
                                if (isSmDown) {
                                    toggleDrawer();
                                }
                            }}
                        >
                            <ListItemButton
                                className={`${
                                    path === i.path &&
                                    'bg-[#336CB7] text-white transition-all hover:bg-[#336CB7]'
                                }`}
                            >
                                <Tooltip title={i.name}>
                                    <ListItemIcon sx={{ minWidth: '40px' }}>
                                        <i.icon
                                            color={'primary'}
                                            className={`${
                                                path === i.path && 'text-white'
                                            }`}
                                        />
                                    </ListItemIcon>
                                </Tooltip>
                                <ListItemText primary={i.name} />
                            </ListItemButton>
                        </Link>
                    ))}
            </List>
        </Drawer>
    );
};

export default Sidebar;
