import {
    ChevronLeft,
    LogoutRounded,
    MedicalInformation,
    MenuRounded,
} from '@mui/icons-material';
import {
    Avatar,
    Chip,
    Divider,
    Menu,
    MenuItem,
    useMediaQuery,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import HCModal from './Modal';
import FacilityModalContent from './modalContent/FacilityModalContent';
import { logoutUser } from '../features/slices/authSlice';
import { getFacilityById } from '../features/slices/facilitySlice';
import { AppDispatch, RootState } from '../features/store';
import { removeCookie } from '../utils/cookieUtils';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${240}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface INavbarProps {
    open: boolean;
    toggleDrawer: () => void;
}

const Navbar = ({ open, toggleDrawer }: INavbarProps) => {
    const { data } = useSelector((state: RootState) => state.auth);
    const { selectedFacility } = useSelector(
        (state: RootState) => state.facility,
    );
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isShowInfo, setIsShowInfo] = useState(false);

    const theme = useTheme();
    const isSmDown = useMediaQuery(theme.breakpoints.down('sm'));

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const openPopover = Boolean(anchorEl);

    const dispatch = useDispatch<AppDispatch>();

    return (
        <>
            {isShowInfo && (
                <HCModal
                    isOpen={isShowInfo}
                    onClose={() => setIsShowInfo(false)}
                    title="Facility"
                    size="md"
                >
                    <FacilityModalContent
                        isView={true}
                        closeModal={() => setIsShowInfo(false)}
                    />
                </HCModal>
            )}
            <AppBar position="absolute" open={open}>
                <Toolbar
                    sx={{
                        pr: '24px', // keep right padding when drawer closed
                        position: 'relative',
                        background: 'linear-gradient(45deg,#336cb7,#4db34f)',
                    }}
                >
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuRounded />
                    </IconButton>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="Close drawer"
                        onClick={toggleDrawer}
                        sx={{
                            ...(!open && { display: 'none' }),
                            border: '1px solid #e2e8f0',
                        }}
                        className="absolute -left-1 bg-white rounded-full shadow-md p-0 hover:bg-white hover:scale-125 transition-all"
                    >
                        <ChevronLeft color="primary" />
                    </IconButton>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                    >
                        Welcome {data?.UserName}!
                    </Typography>

                    <>
                        {isSmDown ? (
                            <Avatar
                                variant="circular"
                                onClick={handlePopoverOpen}
                                sx={{
                                    background: 'white',
                                    color: 'black',
                                    textWrap: 'wrap',
                                }}
                            >
                                {data.UserName[0]}
                            </Avatar>
                        ) : (
                            <Chip
                                avatar={
                                    <Avatar
                                        variant="circular"
                                        className="bg-[#4DB34F]"
                                    >
                                        <span className="text-white font-bold text-wrap">
                                            {data.UserName[0]}
                                        </span>
                                    </Avatar>
                                }
                                label={data?.UserRole}
                                sx={{ backgroundColor: 'white' }}
                                variant="filled"
                                onClick={handlePopoverOpen}
                            />
                        )}
                    </>

                    <Menu
                        id="menu"
                        anchorEl={anchorEl}
                        open={openPopover}
                        onClose={handlePopoverClose}
                        MenuListProps={{
                            'aria-labelledby': 'menu-button',
                        }}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                width: 200,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    right: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        className="min-w-[180px]"
                        transformOrigin={{
                            horizontal: 'right',
                            vertical: 'top',
                        }}
                        anchorOrigin={{
                            horizontal: 'right',
                            vertical: 'bottom',
                        }}
                    >
                        <MenuItem sx={{ fontWeight: 'bold', textWrap: 'wrap' }}>
                            {data?.UserName}
                        </MenuItem>
                        <Divider
                            sx={{ borderStyle: 'dashed', m: '0px !important' }}
                        />

                        {isSmDown && (
                            <>
                                <Divider
                                    sx={{
                                        borderStyle: 'dashed',
                                        m: '0px !important',
                                    }}
                                />
                                <MenuItem>
                                    {isSmDown && data?.UserRole}
                                </MenuItem>
                            </>
                        )}

                        {data?.UserRole === 'FacilityUser' && (
                            <>
                                <MenuItem
                                    sx={{ fontWeight: 'bold' }}
                                    onClick={() => {
                                        setIsShowInfo(!isShowInfo);
                                        if (
                                            !selectedFacility?.facilityProfile
                                                .userId ||
                                            selectedFacility?.facilityProfile
                                                ?.userId !== data?.UserID
                                        ) {
                                            dispatch(
                                                getFacilityById(data?.UserID),
                                            );
                                        }
                                    }}
                                >
                                    <MedicalInformation
                                        classes={{ root: 'mr-2' }}
                                    />{' '}
                                    My Info
                                </MenuItem>
                                <Divider
                                    sx={{
                                        borderStyle: 'dashed',
                                        m: '0px !important',
                                    }}
                                />
                            </>
                        )}
                        <Divider
                            sx={{ borderStyle: 'dashed', m: '0px !important' }}
                        />

                        <MenuItem
                            classes={{ root: 'text-red-500' }}
                            onClick={() => {
                                dispatch(logoutUser()).then((res) => {
                                    if (res?.payload?.responseCode === '200') {
                                        removeCookie('session');
                                        window.location.href = '/';
                                    }
                                });
                            }}
                        >
                            <LogoutRounded classes={{ root: 'mr-2' }} /> Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Navbar;
