import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { REPORTS_INITIAL_STATE } from '../constants/reports.constants';
import {
    GeneralCaseLoadPayload,
    IndividualCaseLoadPayload,
    PaymentPayload,
} from '../types/reports.types';

export const getPaymentReports = createAsyncThunk(
    'reports/payment',
    async (data: PaymentPayload) => {
        const response = await axios.post('/Reports/Payment', data);
        return response.data;
    },
);

export const getGeneralCaseLoadReports = createAsyncThunk(
    'reports/GeneralCaseLoad',
    async (data: GeneralCaseLoadPayload) => {
        const response = await axios.post('/Reports/GeneralCaseLoad', data);
        return response.data;
    },
);

export const getPatientRegistrationReport = createAsyncThunk(
    'reports/PatientRegistrationReport',
    async (data: GeneralCaseLoadPayload) => {
        const response = await axios.post(
            `/Reports/PatientRegistrationData?filterMonth=${data.month}&filterYear=${data.year}`,
        );
        return response.data;
    },
);

export const getIndividualCaseLoadReports = createAsyncThunk(
    'reports/IndividualCaseLoad',
    async (data: IndividualCaseLoadPayload) => {
        const response = await axios.post(
            '/Reports/IndividualCaseLoadReport',
            data,
        );
        return response.data;
    },
);

export const getTriageAndVitalReport = createAsyncThunk(
    'reports/TriageAndVitalReport',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: any) => {
        const response = await axios.post(
            `/Reports/PatientTriageVitalData`,
            data,
        );
        return response.data;
    },
);

export const reportsSlice = createSlice({
    name: 'reports',
    initialState: REPORTS_INITIAL_STATE,
    reducers: {
        onTriageAndVitalPropsChange: (state, action) => {
            state.triageAndVitalReportProps = action.payload;
        },
        onResetReportsData: (state, action) => {
            state.paymentData = action.payload;
            state.generalCaseLoadData = action.payload;
            state.individualCaseLoadData = action.payload;
            state.patientRegistrationData = action.payload;
            state.triageAndVitalData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPaymentReports.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getPaymentReports.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.paymentData = action.payload;
                state.error = '';
            })
            .addCase(getPaymentReports.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getGeneralCaseLoadReports.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getGeneralCaseLoadReports.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.generalCaseLoadData = action.payload?.result ?? [];
                state.error = '';
            })
            .addCase(getGeneralCaseLoadReports.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getIndividualCaseLoadReports.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(
                getIndividualCaseLoadReports.fulfilled,
                (state, action) => {
                    state.loading = 'succeeded';
                    state.individualCaseLoadData = action.payload?.result ?? [];
                    state.error = '';
                },
            )
            .addCase(getIndividualCaseLoadReports.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getPatientRegistrationReport.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(
                getPatientRegistrationReport.fulfilled,
                (state, action) => {
                    state.loading = 'succeeded';
                    state.patientRegistrationData =
                        action.payload?.result ?? [];
                    state.error = '';
                },
            )
            .addCase(getPatientRegistrationReport.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getTriageAndVitalReport.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getTriageAndVitalReport.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.triageAndVitalData = action.payload?.result;
                state.error = '';
            })
            .addCase(getTriageAndVitalReport.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            });
    },
});

export const { onResetReportsData, onTriageAndVitalPropsChange } =
    reportsSlice.actions;

export default reportsSlice.reducer;
