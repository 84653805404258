import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { DASHBORD_INTIAL_STATE } from '../constants/dashboardSlice.constants';

export const getDashboardData = createAsyncThunk(
    'adminDashboard/get',
    async (url: string) => {
        const response = await axios.get(url);
        return response.data;
    },
);

export const getPatientVisitMTDGraphData = createAsyncThunk(
    'adminDashboard/patientVisitMTDGraph',
    async () => {
        const response = await axios.post('/Dashboard/PatientVisitPaymentMTD');
        return response.data;
    },
);

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState: DASHBORD_INTIAL_STATE,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getDashboardData.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.data = action.payload?.result || [];
                state.error = null;
            })
            .addCase(getDashboardData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message || 'An error occurred.';
            })
            .addCase(getPatientVisitMTDGraphData.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getPatientVisitMTDGraphData.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.patientVisitMTD = action.payload?.list;
                state.error = null;
            })
            .addCase(getPatientVisitMTDGraphData.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message || 'An error occurred.';
            });
    },
});

export default dashboardSlice.reducer;
