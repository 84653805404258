import {
    DashboardRounded,
    GroupRounded,
    LocalHospitalRounded,
    MasksRounded,
    NoteAddRounded,
    HandshakeRounded,
    MedicalServicesRounded,
    LanguageRounded,
    VaccinesRounded,
    ScienceRounded,
    WorkspacesRounded,
    QueryStatsRounded,
    LocalLibraryRounded,
} from '@mui/icons-material';

export const routes = [
    {
        name: 'Dashboard',
        icon: DashboardRounded,
        permission: [
            'SuperAdmin',
            'SystemAdministrator',
            'FacilityUser',
            'CHO',
            'Doctor',
            'Receptionist',
            'Nurse',
            'LabUser',
        ],
        path: '/dashboard',
    },
    {
        name: 'Users',
        icon: GroupRounded,
        permission: ['SuperAdmin', 'SystemAdministrator', 'FacilityUser'],
        path: '/users',
    },
    {
        name: 'Facility',
        icon: LocalHospitalRounded,
        permission: ['SuperAdmin', 'SystemAdministrator'],
        path: '/facility',
    },
    {
        name: 'Units',
        icon: LocalLibraryRounded,
        permission: ['SuperAdmin', 'SystemAdministrator'],
        path: '/units',
    },
    {
        name: 'Patients',
        icon: MasksRounded,
        permission: [
            'SuperAdmin',
            'SystemAdministrator',
            'FacilityUser',
            'Receptionist',
            'Nurse',
        ],
        path: '/patients',
    },
    {
        name: 'Patients Visit',
        icon: NoteAddRounded,
        permission: [
            'FacilityUser',
            'SuperAdmin',
            'SystemAdministrator',
            'CHO',
            'Doctor',
            'Receptionist',
            'Nurse',
        ],
        path: '/patients-visit',
    },
    {
        name: 'Consultation',
        icon: HandshakeRounded,
        permission: [
            'CHO',
            'Doctor',
            'SuperAdmin',
            'SystemAdministrator',
            'FacilityUser',
        ],
        path: '/consultation',
    },
    {
        name: 'Services',
        icon: MedicalServicesRounded,
        permission: ['SuperAdmin', 'SystemAdministrator'],
        path: '/services',
    },
    {
        name: 'ICD 10',
        icon: LanguageRounded,
        permission: ['SuperAdmin', 'SystemAdministrator'],
        path: '/icd-10',
    },
    {
        name: 'Drugs',
        icon: VaccinesRounded,
        permission: ['SuperAdmin', 'SystemAdministrator', 'Doctor'],
        path: '/drugs',
    },
    {
        name: 'Lab Diagnostic',
        icon: ScienceRounded,
        permission: ['SuperAdmin', 'SystemAdministrator'],
        path: '/lab-diagnostic',
    },
    {
        name: 'Lab Orders',
        icon: WorkspacesRounded,
        permission: [
            'LabUser',
            'SuperAdmin',
            'SystemAdministrator',
            'FacilityUser',
        ],
        path: '/lab-orders',
    },
    {
        name: 'Reports',
        icon: QueryStatsRounded,
        permission: ['SuperAdmin', 'SystemAdministrator', 'FacilityUser'],
        path: '/reports',
    },
];
