import { IFacilityApiState } from '../types/facility.types';

export const FACILITY_PROFILE_INITIAL_STATE = {
    id: 0,
    userId: '',
    name: '',
    address: '',
    district: '',
    phone: '',
    email: '',
    category: '',
    noOfStaff_Male: 0,
    noOfStaff_Female: 0,
    isBlocked: false,
    createdDate: '',
    updatedDate: '',
    status: '',
    createdBy: '',
    updatedBy: '',
    isDelete: false,
    isLab: false,
};

export const FACILITY_INITIAL_STATE: IFacilityApiState = {
    data: {
        list: [],
        totalPages: 0,
        totalRecords: 0,
    },
    loading: 'idle',
    error: null,
    pageProps: {
        isDescending: false,
        pageNumber: 0,
        perPage: 0,
        searchText: '',
        sortingOn: '',
    },
    list: [],
    associatedServices: [],
    associatedUsers: [],
    drAndCho: [],
    selectedFacility: {
        facilityUsers: [],
        serviceFees: [],
        facilityServices: [],
        facilityProfile: FACILITY_PROFILE_INITIAL_STATE,
        facilityLabServices: [],
        facilityUnitAndUsers: []
    },
};
