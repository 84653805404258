/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { AUTH_INITIAL_STATE } from '../constants/authSlice.constants';
import { IAuthPayload, IRestPasswordEmail } from '../types/authSlice.types';

export const loginUser = createAsyncThunk(
    'auth/login',
    async (requestData: IAuthPayload) => {
        const response: any = await axios.post('/Auth/Login', requestData);

        return response.data || response?.response?.data;
    },
);

export const logoutUser = createAsyncThunk('auth/logout', async () => {
    const response = await axios.post('/Auth/LogOff');
    return response.data;
});

export const sendResetPasswordEmail = createAsyncThunk(
    'auth/resetPasswordEmail',
    async (payload: IRestPasswordEmail) => {
        const response = await axios.post('/Auth/ResetPassword', payload);
        return response.data;
    },
);

export const getESign = createAsyncThunk(
    'auth/getESign',
    async (id: string) => {
        const response = await axios.get('/Auth/GetESigned?id=' + id);
        return response.data;
    },
);

export const authSlice = createSlice({
    name: 'auth',
    initialState: AUTH_INITIAL_STATE,
    reducers: {
        setUserData(state, payload) {
            state.data = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginUser.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(loginUser.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.data = action.payload;
                state.error = null;
            })
            .addCase(loginUser.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message || 'An error occurred.';
            })
            .addCase(logoutUser.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(logoutUser.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(logoutUser.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message || 'An error occurred.';
            })
            .addCase(sendResetPasswordEmail.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(sendResetPasswordEmail.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(sendResetPasswordEmail.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message || 'An error occurred.';
            })
            .addCase(getESign.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getESign.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.eSign = action.payload?.result[0]?.eSignedData || '';
                state.error = null;
            })
            .addCase(getESign.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message || 'An error occurred.';
            });
    },
});

export const { setUserData } = authSlice.actions;

export default authSlice.reducer;
