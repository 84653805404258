import { ILabOrder, ILabOrderApiState } from '../types/LabOrders.types';

export const LAB_ORDER_INITIAL_DATA: ILabOrder = {
    id: 0,
    patientName: '',
    facilityName: '',
    consultationId: 0,
    patientVisitId: 0,
    labOrderId: 0,
    labOrderType: '',
    createdDate: '',
    updatedDate: '',
    createdBy: '',
    updatedBy: '',
    isDelete: false,
    status: '',
    labServiceArea: '',
    labServiceSubArea: '',
    fees: '',
    consultation: '',
    consultBy: 0,
    patientId: 0,
    facilityId: 0,
    age: "",
    gender: "",
    emr: ""
};

export const LAB_ORDER_INITIAL_STATE: ILabOrderApiState = {
    data: {
        list: [],
        totalPages: 0,
        totalRecords: 0,
    },
    selectedLabOrderResult: LAB_ORDER_INITIAL_DATA,
    pageProps: {
        isDescending: false,
        pageNumber: 1,
        perPage: 10,
        searchText: '',
        sortingOn: '',
    },
    list: [],
    labOrderType: 'internal',
    loading: 'idle',
    error: null,
};
