/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { TableSortArgs } from '../../components/TableV2';
import { PAGE_PROPS } from '../constants/common.constants';
import { FACILITY_INITIAL_STATE } from '../constants/facilitySlice.constants';
import {
    IAddFacilityUserPayload,
    IAddServiceFeePayload,
    // IFacility,
    IFacilityLabServicePayload,
    IFacilityServicePayload,
    IRemoveFacilityUserPayload,
} from '../types/facility.types';

export const getAllFacilities = createAsyncThunk(
    'facilities/get',
    async (data: TableSortArgs) => {
        const response = await axios.post('/Facility/Search', data);
        return response.data;
    },
);

export const getAllFacilitiesList = createAsyncThunk(
    'facilities/getAll',
    async () => {
        const response = await axios.get('/Facility/GetFacilities');
        return response.data;
    },
);

export const createFacility = createAsyncThunk(
    'facilities/save',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: any) => {
        const response = await axios.post('/Facility/Save', data);
        return response;
    },
);

export const getFacilityById = createAsyncThunk(
    'facility/getByID',
    async (id: string) => {
        const response = await axios.post(
            `/Facility/GetFacilityAssociatedDetailsById/${id}`,
        );
        return response.data;
    },
);

export const addFacilityUsers = createAsyncThunk(
    'facility/addUsers',
    async (data: IAddFacilityUserPayload[] | []) => {
        const response = await axios.post('/Facility/AddUsers', data);
        return response.data;
    },
);

export const removeFacilityUsers = createAsyncThunk(
    'facility/removeUsers',
    async (data: IRemoveFacilityUserPayload[] | []) => {
        const response = await axios.post('/Facility/RemoveUsers', data);
        return response.data;
    },
);

export const addFacilityServices = createAsyncThunk(
    'facility/addServices',
    async (data: IFacilityServicePayload[] | []) => {
        const response = await axios.post('/Facility/AddServices', data);
        return response.data;
    },
);

export const removeFacilityServices = createAsyncThunk(
    'facility/removeServices',
    async (data: IFacilityServicePayload[] | []) => {
        const response = await axios.post('/Facility/RemoveServices', data);
        return response.data;
    },
);

export const addFacilityServiceFees = createAsyncThunk(
    'facility/AddServicesFee',
    async (data: IAddServiceFeePayload[] | []) => {
        const response = await axios.post('/Facility/AddServicesFee', data);
        return response.data;
    },
);

export const getAssociatedFacilityServices = createAsyncThunk(
    'facility/associatedServices',
    async (id: string) => {
        const response = await axios.post(
            'Facility/GetFacilityAssociatedServices/' + id,
        );
        return response.data;
    },
);

export const getAssociatedFacilityUsers = createAsyncThunk(
    'facility/associatedUsers',
    async (id: string) => {
        const response = await axios.post(
            'Facility/GetFacilityAssociatedUssersById/' + id,
        );
        return response.data;
    },
);

export const getFacilityAssociatedDRandCHO = createAsyncThunk(
    'facility/associatedDrAndCHO',
    async (id: string) => {
        const response = await axios.post(
            '/Facility/GetFacilityAssociatedDRandCHOById/' + id,
        );
        return response.data;
    },
);

export const addFacilityLabServices = createAsyncThunk(
    'facility/addLabServices',
    async (data: IFacilityLabServicePayload[] | []) => {
        const response = await axios.post('/Facility/AddLabServices', data);
        return response.data;
    },
);

export const removeFacilityLabServices = createAsyncThunk(
    'facility/removeLabServices',
    async (data: IFacilityLabServicePayload[] | []) => {
        const response = await axios.post('/Facility/RemoveLabServices', data);
        return response.data;
    },
);

export const addFacilityUnits = createAsyncThunk(
    'facility/addUnits',
    async (data: any) => {
        const response = await axios.post('/Facility/AddUnits', data);
        return response.data;
    },
);

export const removeFacilityUnits = createAsyncThunk(
    'facility/removeUnits',
    async (id: string) => {
        const response = await axios.post(`/Facility/RemoveUnits?Id=${id}`);
        return response.data;
    },
);

export const facilitiesSlice = createSlice({
    name: 'facilities',
    initialState: FACILITY_INITIAL_STATE,
    reducers: {
        onChangePage: (state, action) => {
            state.pageProps = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllFacilities.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllFacilities.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.data = action.payload?.result || PAGE_PROPS;
                state.error = null;
            })
            .addCase(getAllFacilities.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getAllFacilitiesList.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllFacilitiesList.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.list = action.payload?.result || [];
                state.error = null;
            })
            .addCase(getAllFacilitiesList.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(createFacility.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(createFacility.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(createFacility.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getFacilityById.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getFacilityById.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.error = null;
                state.selectedFacility = action.payload?.result;
            })
            .addCase(getFacilityById.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(addFacilityUsers.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(addFacilityUsers.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(addFacilityUsers.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(removeFacilityUsers.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(removeFacilityUsers.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(removeFacilityUsers.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(addFacilityUnits.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(addFacilityUnits.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(addFacilityUnits.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(removeFacilityUnits.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(removeFacilityUnits.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(removeFacilityUnits.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(addFacilityServices.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(addFacilityServices.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(addFacilityServices.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(addFacilityServiceFees.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(addFacilityServiceFees.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(addFacilityServiceFees.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getAssociatedFacilityServices.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(
                getAssociatedFacilityServices.fulfilled,
                (state, action) => {
                    state.loading = 'succeeded';
                    state.associatedServices = action.payload?.result || [];
                    state.error = null;
                },
            )
            .addCase(
                getAssociatedFacilityServices.rejected,
                (state, action) => {
                    state.loading = 'failed';
                    state.error = action.error.message ?? 'An error occurred.';
                },
            )
            .addCase(getAssociatedFacilityUsers.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAssociatedFacilityUsers.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.associatedUsers = action.payload?.result || [];
                state.error = null;
            })
            .addCase(getAssociatedFacilityUsers.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getFacilityAssociatedDRandCHO.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(
                getFacilityAssociatedDRandCHO.fulfilled,
                (state, action) => {
                    state.loading = 'succeeded';
                    state.drAndCho = action.payload?.result || [];
                    state.error = null;
                },
            )
            .addCase(
                getFacilityAssociatedDRandCHO.rejected,
                (state, action) => {
                    state.loading = 'failed';
                    state.error = action.error.message ?? 'An error occurred.';
                },
            );
    },
});

export const { onChangePage } = facilitiesSlice.actions;

export default facilitiesSlice.reducer;
