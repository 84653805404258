import React from 'react';

const HCLoader = () => {
    return (
        <div
            className="inline-block h-6 w-6 m-2 animate-spin rounded-full border-3 border-solid border-x-[#336CB7] border-y-[#4DB34F] align-[-0.125em] text-success motion-reduce:animate-[spin_1s_linear_infinite]"
            role="status"
        >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
            </span>
        </div>
    );
};

export default HCLoader;
