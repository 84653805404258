import { ICDApiState } from '../types/icd.types';

export const ICD_INITIAL_STATE: ICDApiState = {
    data: {
        list: [],
        totalPages: 0,
        totalRecords: 0,
    },
    pageProps: {
        isDescending: false,
        pageNumber: 0,
        perPage: 0,
        searchText: '',
        sortingOn: '',
    },
    icdList: [],
    loading: 'idle',
    error: null,
};
