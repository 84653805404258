import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    addFacilityLabServices,
    getFacilityById,
    removeFacilityLabServices,
} from '../../features/slices/facilitySlice';
import { getAllLabOrdersMasterList } from '../../features/slices/labDiagnosticSlice';
import { AppDispatch, RootState } from '../../features/store';
import MultiSelectInput from '../MultiSelect';

interface ILabOptionType {
    label: string;
    value: number;
    delete: boolean;
    block: boolean;
}

const AddLabServicesForm = ({
    edit,
}: {
    edit: boolean;
    onClose?: () => void;
}) => {
    const { selectedFacility, loading } = useSelector(
        (state: RootState) => state.facility,
    );
    const { labOrderMaterList } = useSelector(
        (state: RootState) => state.labDiagnostic,
    );
    const { data: user } = useSelector((state: RootState) => state.auth);

    const dispatch = useDispatch<AppDispatch>();
    useEffect(() => {
        if (!labOrderMaterList.length && !edit) {
            dispatch(getAllLabOrdersMasterList());
        }
    }, []);

    const handleSelectedLabServicesChange = (newValues: ILabOptionType[]) => {
        const filteredValues = selectedFacility.facilityLabServices.map(
            (i) => i.id,
        );
        const payload = newValues
            .filter((i) => !filteredValues.includes(i.value))
            .map((i) => ({
                labServiceId: i.value,
                facilityId: selectedFacility?.facilityProfile?.id,
                createdBy: user?.UserID,
            }));
        dispatch(addFacilityLabServices(payload)).then((res) => {
            if (res.payload?.responseCode === '200') {
                dispatch(
                    getFacilityById(selectedFacility?.facilityProfile?.userId),
                );
            }
        });
    };

    const onRemoveLabServices = (obj: ILabOptionType) => {
        const payload = {
            facilityId: selectedFacility?.facilityProfile?.id,
            labServiceId: obj.value,
            createdBy: user?.UserID,
        };
        dispatch(removeFacilityLabServices([payload])).then((res) => {
            if (res.payload?.responseCode === '200') {
                dispatch(
                    getFacilityById(selectedFacility?.facilityProfile?.userId),
                );
            }
        });
    };

    return (
        <div>
            {selectedFacility.facilityProfile?.isLab ? (
                <MultiSelectInput<ILabOptionType>
                    selectedValues={selectedFacility?.facilityLabServices
                        ?.filter((i) => !i?.isDelete)
                        ?.map((i) => ({
                            value: i.id,
                            label: `${i.facilityLabService} (${i.facilityLabSubService})`,
                            delete: i.isDelete,
                            block: i.isBlocked,
                        }))}
                    options={labOrderMaterList.map((i) => ({
                        value: i.id,
                        label: `${i.labServiceArea} (${i.labServiceSubArea})`,
                        delete: i?.isDelete,
                        block: i?.isBlocked,
                    }))}
                    handleDelete={onRemoveLabServices}
                    title="Lab services"
                    getOptionLabel={(option) => option.label}
                    placeholder="Select lab services"
                    disabled={loading === 'pending'}
                    disableAll={edit}
                    onSelectedValuesChange={handleSelectedLabServicesChange}
                />
            ) : null}
        </div>
    );
};

export default AddLabServicesForm;
