/* eslint-disable @typescript-eslint/no-explicit-any */
import jwtDecode from 'jwt-decode';

interface decodeJWT {
    UserProfile: string;
}
export const getUserRoleByToken = (token: string) => {
    try {
        const decode: decodeJWT = jwtDecode(token);
        if (decode?.UserProfile) {
            return JSON.parse(decode?.UserProfile)?.UserRole;
        }
    } catch (error) {
        return '';
    }
};

export const splitCamelCase = (input: string) => {
    return input.replace(/[_]|([a-z])([A-Z])/g, (_, g1, g2) =>
        g1 ? `${g1} ${g2}` : ' ',
    );
};

export const capitalizeFirstLetter = (input: string) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
};

export const getRedirectRoute = (role: string) => {
    switch (role) {
        case 'SuperAdmin':
        case 'FacilityUser':
        case 'CHO':
        case 'Receptionist':
        case 'Nurse':
        case 'Doctor':
        case 'SystemAdministrator':
        case 'LabUser':
            return '/dashboard';
        default:
            return '/';
    }
};

export const getRolePermissions = (role: string, tab: string): boolean[] => {
    if (tab === '/patients' || tab === '/facility') {
        return patientsListTabPermissions(role);
    } else if (tab === '/patients-visit') {
        return patientVisitTab(role);
    } else if (tab === '/consultation') {
        return consultationTabPermissions(role);
    } else if (tab === '/lab-orders') {
        return labOrdersTabPermissions(role);
    } else if (tab === '/drugs') {
        return drugPermissions(role);
    } else {
        return [false, true, false, false];
    }
};

export const drugPermissions = (role: string) => {
    // [...]; create, view, edit, delete, createVisit for patient(facility User), vital and triage, assign clinic, create consultation (patient visit), sendEmail(consultation), create lab order results
    if (
        role === 'SuperAdmin' ||
        role === 'SystemAdministrator' ||
        role === 'Doctor'
    ) {
        return [true, true, true, true];
    } else {
        return [false, true, false, false];
    }
};

export const patientsListTabPermissions = (role: string) => {
    // [...]; create, view, edit, delete, createVisit for patient(facility User), vital and triage, assign clinic, create consultation (patient visit), sendEmail(consultation), create lab order results
    if (role === 'SuperAdmin' || role === 'SystemAdministrator') {
        return [true, true, true, true];
    } else if (role === 'FacilityUser') {
        return [true, true, true, false, true];
    } else if (role === 'Receptionist') {
        return [true, true, true, false, true, false, false, false, false];
    } else {
        return [false, true, false, false];
    }
};

export const validateEmail = (email: string) => {
    // Regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

export const getBase64 = async (file: File): Promise<string> => {
    return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            resolve(reader?.result as string);
        };
        reader.onerror = reject;
    });
};

export const patientVisitTab = (role: string) => {
    if (role === 'SuperAdmin') {
        return [false, true, false, false];
    } else if (
        role === 'FacilityUser' ||
        role === 'Receptionist' ||
        role === 'Nurse'
    ) {
        return [false, true, false, false, false, true, true, false];
    } else if (role === 'CHO' || role === 'Doctor') {
        return [false, true, false, false, false, false, false, true];
    } else {
        return [false, true, false, false];
    }
};

export const consultationTabPermissions = (role: string) => {
    if (role === 'CHO') {
        return [false, true, true, false, false, false, false, false, true];
    } else if (role === 'Doctor') {
        return [false, true, true, false, false, false, false, false, true];
    } else {
        return [false, true, false, false, false, false, false, false, true];
    }
};

export const labOrdersTabPermissions = (role: string) => {
    if (role === 'LabUser') {
        return [
            false,
            true,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true,
        ];
    } else {
        return [
            false,
            true,
            false,
            false,
            false,
            false,
            false,
            false,
            false,
            true,
        ];
    }
};

export const getDashboardDataUrls = (role: string) => {
    switch (role) {
        case 'SuperAdmin':
        case 'SystemAdministrator':
            return '/Dashboard/SuperAdmin';
        case 'FacilityUser':
            return '/Dashboard/Facility';
        case 'CHO':
        case 'Doctor':
            return '/Dashboard/DRandCHO';
        case 'LabUser':
            return '/Dashboard/LabUser';
        default:
            return '/Dashboard/Facility';
    }
};

export const downloadFile = (value: string) => {
    const linkSource = value;
    const downloadLink = document.createElement('a');

    downloadLink.href = linkSource;
    downloadLink.download = 'download';
    downloadLink.click();
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepCompareObjs(obj1: any, obj2: any) {
    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
        return obj1 !== obj2;
    }

    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
        return true;
    }

    for (const key in obj1) {
        if (Object.hasOwn(obj1, key)) {
            if (
                !Object.prototype.hasOwnProperty.call(obj2, key) ||
                deepCompareObjs(obj1[key], obj2[key])
            ) {
                return true;
            }
        }
    }

    return false;
}

export const imageFileCheck = (image: string) => {
    return image?.includes('data:image')
        ? image
        : `data:image/png;base64,${image}`;
};

export const pdfFileCheck = (file: string) => {
    return file?.includes('data:application')
        ? file
        : `data:application/pdf;base64,${file}`;
};

export const compareTwoObjects = (
    obj1: Record<string, any>,
    obj2: Record<string, any>,
) => {
    let isChangeExist = false;

    if (
        Object.keys(obj1).length !== Object.keys(obj2).length ||
        Object.values(obj1).length !== Object.values(obj2).length
    ) {
        return true;
    }

    Object.keys(obj1).forEach((key) => {
        if (obj1[key] !== obj2[key]) {
            isChangeExist = true;
        }
    });

    return isChangeExist;
};
