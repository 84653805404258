import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { TableSortArgs } from '../../components/TableV2';
import { PAGE_PROPS } from '../constants/common.constants';
import { ICD_INITIAL_STATE } from '../constants/icdSlice.constants';

export const getAllIcd = createAsyncThunk(
    'icd/get',
    async (data: TableSortArgs) => {
        const response = await axios.post('/ICD10/Search', data);
        return response.data;
    },
);

export const getAllIcdList = createAsyncThunk('icd/getList', async () => {
    const response = await axios.get('/ICD10/GetAll');
    return response.data;
});

export const icdSlice = createSlice({
    name: 'icd',
    initialState: ICD_INITIAL_STATE,
    reducers: {
        onIcdPageChange: (state, action) => {
            state.pageProps = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllIcd.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllIcd.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.data = action.payload?.result || PAGE_PROPS;
                state.error = null;
            })
            .addCase(getAllIcd.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getAllIcdList.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllIcdList.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.icdList = action.payload?.result || [];
                state.error = null;
            })
            .addCase(getAllIcdList.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            });
    },
});

export const { onIcdPageChange } = icdSlice.actions;

export default icdSlice.reducer;
