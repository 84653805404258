import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import App from './App';
import { store } from './features/store';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from './theme/index';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
    <React.StrictMode>
        <StyledEngineProvider injectFirst>
            <React.Suspense>
                <HelmetProvider>
                    <ThemeProvider>
                        <CssBaseline />
                        <Provider store={store}>
                            <App />
                        </Provider>
                    </ThemeProvider>
                </HelmetProvider>
            </React.Suspense>
        </StyledEngineProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
