import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { TableSortArgs } from '../../components/TableV2';
import { PAGE_PROPS } from '../constants/common.constants';
import { PATIENT_VISIT_INITIAL_STATE } from '../constants/patientVisitSlice.constants';
import {
    IPatientVisitAssignClinicPayload,
    IPatientVisitAssignClinicPayloadRefer,
    IPatientVisitTriageAndVital,
} from '../types/patientVisit.types';

export const getPatientVisitList = createAsyncThunk(
    'patientVisit/get',
    async (data: TableSortArgs) => {
        const response = await axios.post('Patient/Visit/Search', data);
        return response.data;
    },
);

export const savePatientVitalAndTriage = createAsyncThunk(
    'patientVisit/saveVitalAndTriage',
    async (data: IPatientVisitTriageAndVital) => {
        const response = await axios.post(
            'Patient/Visit/TriageVital/Save',
            data,
        );
        return response.data;
    },
);

export const updatePatientVitalAndTriage = createAsyncThunk(
    'patientVisit/updateVitalAndTriage',
    async (data: IPatientVisitTriageAndVital) => {
        const response = await axios.post(
            'Patient/Visit/TriageVital/Update',
            data,
        );
        return response.data;
    },
);

export const assignClinician = createAsyncThunk(
    'patientVisit/assignClinician',
    async (data: IPatientVisitAssignClinicPayload) => {
        const response = await axios.post(
            '/Patient/Visit/AssignClinician/Save',
            data,
        );
        return response.data;
    },
);

export const assignToUnit = createAsyncThunk(
    'patientVisit/assignToUnit',
    async (data: IPatientVisitAssignClinicPayload) => {
        const response = await axios.post(
            '/Patient/Visit/AssignUnit/Save',
            data,
        );
        return response.data;
    },
);

export const assignClinicianRefer = createAsyncThunk(
    'patientVisit/assignClinicianRefer',
    async (data: IPatientVisitAssignClinicPayloadRefer) => {
        const response = await axios.post(
            '/Patient/Visit/AssignClinicianRefer/Save',
            data,
        );
        return response.data;
    },
);

export const getPatientVisitListById = createAsyncThunk(
    'patientVisit/getVisitById',
    async (data: TableSortArgs) => {
        const response = await axios.post('/Patient/Visit/SearchById', data);
        return response.data;
    },
);

export const patientVisitSlice = createSlice({
    name: 'patientVisit',
    initialState: PATIENT_VISIT_INITIAL_STATE,
    reducers: {
        onPatientVisitPageChange: (state, action) => {
            state.pageProps = action.payload;
        },
        onChangeStatus: (state, action) => {
            state.status = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPatientVisitList.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getPatientVisitList.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.data = action.payload?.result || PAGE_PROPS;
                state.error = null;
            })
            .addCase(getPatientVisitList.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(savePatientVitalAndTriage.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(savePatientVitalAndTriage.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(savePatientVitalAndTriage.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(updatePatientVitalAndTriage.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(updatePatientVitalAndTriage.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(updatePatientVitalAndTriage.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(assignClinician.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(assignClinician.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(assignClinician.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(assignClinicianRefer.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(assignClinicianRefer.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(assignClinicianRefer.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(assignToUnit.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(assignToUnit.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(assignToUnit.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getPatientVisitListById.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getPatientVisitListById.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.visits = action.payload?.result?.list || [];
                state.error = null;
            })
            .addCase(getPatientVisitListById.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
    },
});

export const { onPatientVisitPageChange, onChangeStatus } =
    patientVisitSlice.actions;

export default patientVisitSlice.reducer;
