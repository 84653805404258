import axios from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import GlobalAlert from './components/GlobalAlert';
import Loader from './components/Loader';
import { setAlert } from './features/slices/alertSlice';
import { AppDispatch } from './features/store';
import { router } from './routes';
import Settings from './Settings.json';
import { getCookie, removeCookie } from './utils/cookieUtils';

function App() {
    const token = getCookie('session') ?? '';
    axios.defaults.baseURL = Settings[process.env.NODE_ENV].base_url;
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;

    const dispatch = useDispatch<AppDispatch>();

    axios.interceptors.response.use(
        function (response) {
            if (
                response?.status === 200 &&
                response?.data?.responseMessage &&
                response?.data?.responseMessage?.trim() !== 'Success' &&
                response.config?.method !== 'get'
            ) {
                dispatch(
                    setAlert({
                        message:
                            response?.data?.responseMessage ||
                            response?.data?.result,
                        severity: 'success',
                    }),
                );
            }
            return response;
        },
        function (error) {
            if (error.response?.status === 401) {
                removeCookie('session');
                dispatch(
                    setAlert({
                        message: 'Session expired',
                        severity: 'error',
                    }),
                );
                window.location.href = '/';
            } else {
                dispatch(
                    setAlert({
                        message:
                            error?.response?.data?.responseMessage ||
                            error?.response?.data?.result ||
                            error?.response?.statusText ||
                            error?.message,
                        severity: 'error',
                    }),
                );
            }
            return error;
        },
    );

    return (
        <>
            <RouterProvider router={router} fallbackElement={<Loader />} />
            <GlobalAlert />
        </>
    );
}

export default App;
