import { IReportsInitialState } from '../types/reports.types';

export const REPORTS_INITIAL_STATE: IReportsInitialState = {
    paymentData: [],
    generalCaseLoadData: [],
    individualCaseLoadData: [],
    patientRegistrationData: {},
    triageAndVitalData: { list: [] },
    triageAndVitalReportProps: {
        facilityIds: [],
        gender: null,
        birthYear: null,
        birthMonth: null,
        searchText: '',
        triageCreatedDate: null,
        pageNumber: 1,
        perPage: 20,
        sortingOn: 'CreatedDate',
        isDescending: true,
    },
    loading: 'idle',
    error: null,
};
