import { configureStore } from '@reduxjs/toolkit';

import alertSlice from './slices/alertSlice';
import authSlice from './slices/authSlice';
import consultationSlice from './slices/consultationSlice';
import dashboardSlice from './slices/dashboardSlice';
import drugsSlice from './slices/drugsSlice';
import facilitiesSlice from './slices/facilitySlice';
import icdSlice from './slices/icdSlice';
import labDiagnosticSlice from './slices/labDiagnosticSlice';
import labOrdersSlice from './slices/labOrdersSlice';
import patientsSlice from './slices/patientsSlice';
import patientVisitSlice from './slices/patientVisitSlice';
import reportsSlice from './slices/reportsSlice';
import serviceMasterSlice from './slices/serviceMasterSlice';
import userSlice from './slices/userSlice';
import unitsSlice from './slices/unitsSlice';

export const store = configureStore({
    reducer: {
        user: userSlice,
        alert: alertSlice,
        auth: authSlice,
        serviceMaster: serviceMasterSlice,
        facility: facilitiesSlice,
        icd: icdSlice,
        patients: patientsSlice,
        drugs: drugsSlice,
        dashboard: dashboardSlice,
        patientVisit: patientVisitSlice,
        consultation: consultationSlice,
        labDiagnostic: labDiagnosticSlice,
        labOrders: labOrdersSlice,
        reports: reportsSlice,
        units: unitsSlice
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
