import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

import { UNITS_INITIAL_STATE } from '../constants/unitsSlice.constants';
import { IUnit } from '../types/unitsSlice.types';

export const getAllUnitsList = createAsyncThunk('units/getList', async () => {
    const response = await axios.get('/UnitMaster/GetAll');
    return response.data;
});

export const getUnitUsers = createAsyncThunk('units/getUserList', async () => {
    const response = await axios.get('/UnitMaster/User/GetAll');
    return response.data;
});

export const createUnit = createAsyncThunk(
    'units/create',
    async (data: Partial<IUnit>) => {
        const response = await axios.post('/UnitMaster/Save', data);
        return response.data;
    },
);

export const addUserToUnit = createAsyncThunk(
    'units/addToUnit',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: Partial<any>) => {
        const response = await axios.post('/UnitMaster/User/Save', data);
        return response.data;
    },
);

export const removeUnitUser = createAsyncThunk(
    'units/removeUnitUser',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async (data: Partial<any>) => {
        const response = await axios.post('/UnitMaster/User/Remove', data);
        return response.data;
    },
);

export const getFacilityUnits = createAsyncThunk(
    'units/getFacilityUnit',
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async () => {
        const response = await axios.get('/UnitMaster/GetAllFacilityUnit');
        return response.data;
    },
);

export const unitsSlice = createSlice({
    name: 'units',
    initialState: UNITS_INITIAL_STATE,
    reducers: {
        onSelectUnit: (state, action) => {
            state.selectedUnit = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllUnitsList.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getAllUnitsList.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.unitsList = action.payload?.result || [];
                state.error = null;
            })
            .addCase(getAllUnitsList.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(createUnit.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(createUnit.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(createUnit.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(addUserToUnit.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(addUserToUnit.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(addUserToUnit.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getUnitUsers.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getUnitUsers.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.unitUsers = action.payload?.result || [];
                state.error = null;
            })
            .addCase(getUnitUsers.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(removeUnitUser.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(removeUnitUser.fulfilled, (state) => {
                state.loading = 'succeeded';
                state.error = null;
            })
            .addCase(removeUnitUser.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
            .addCase(getFacilityUnits.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(getFacilityUnits.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.facilityUnit = action.payload?.result || [];
                state.error = null;
            })
            .addCase(getFacilityUnits.rejected, (state, action) => {
                state.loading = 'failed';
                state.error = action.error.message ?? 'An error occurred.';
            })
    },
});

export const { onSelectUnit } = unitsSlice.actions;

export default unitsSlice.reducer;
