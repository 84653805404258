import { IUserApiState } from '../types/users.types';

export const INITIAL_USER_DATA = {
    userId: '',
    firstName: '',
    lastName: '',
    gender: '',
    email: '',
    address: '',
    mobileCodeId: 0,
    mobileNumber: '',
    profileImage: null,
    eSigned: '',
    roleId: '',
    isBlocked: false,
    createdDate: '',
    updatedDate: null,
    createdBy: '',
    updatedBy: null,
    isDelete: false,
    roleName: '',
    refreshToken: null,
    refreshTokenExpiryTime: null,
};

export const USER_INITIAL_STATE: IUserApiState = {
    data: [],
    pageProps: {
        isDescending: false,
        pageNumber: 0,
        perPage: 0,
        searchText: '',
        sortingOn: '',
    },
    selectedUser: INITIAL_USER_DATA,
    loading: 'idle',
    error: null,
};
