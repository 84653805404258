import { ArrowBackRounded, Close } from '@mui/icons-material';
import { Container, Dialog, DialogContent, IconButton } from '@mui/material';
import React from 'react';
interface HCModalProps {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
    size?: 'md' | 'sm' | 'lg' | 'xl';
    label?: string;
    showBackIcon?: boolean;
    onBack?: () => void,
    isHide?: boolean
}

const HCModal: React.FC<HCModalProps> = ({
    isOpen,
    onClose,
    title,
    children,
    label = '',
    size = 'md',
    showBackIcon,
    onBack,
    isHide
}) => {
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={size} sx={{ width: "100%", visibility: isHide ? "hidden" : "block" }}>
            <Container className="flex w-full bg-[#336CB7] items-center text-white px-6 py-3 justify-between">
                <div className='flex gap-2 items-center'>
                    {
                        showBackIcon ?
                            <IconButton onClick={onBack}>
                                <ArrowBackRounded className="text-white " />
                            </IconButton> : <></>
                    }
                    <div>
                        <h3 className="text-xl m-0 p-0">{title}</h3>
                        <p className="text-sm m-0 p-0">{label}</p>
                    </div>
                </div>
                <IconButton onClick={onClose}>
                    <Close className="text-white " />
                </IconButton>
            </Container>
            <DialogContent className="p-6 mt-3 pt-3 w-full bg-[#336CBFF]">
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default HCModal;
