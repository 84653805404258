import { IPatientVisitApiState } from '../types/patientVisit.types';

export const PATIENT_VISIT_INITIAL_DATA = {
    id: 0,
    patientId: 0,
    facilityId: 1,
    facilityName: '',
    patientName: '',
    serviceFeesTotal: '',
    paymentType: '',
    paymentStatus: '',
    status: '',
    vitalTriage: false,
    isBlocked: false,
    createdDate: '',
    updatedDate: null,
    createdBy: '',
    updatedBy: null,
    isDelete: false,
    consultationById: null,
    consultationByName: '',
    consultationByUserId: '',
    patientVisitInfo: [],
    patientTriageVitalinfo: [],
    patientVisitServices: null,
};

export const PATIENT_VISIT_INITIAL_STATE: IPatientVisitApiState = {
    data: {
        list: [],
        totalPages: 0,
        totalRecords: 0,
    },
    pageProps: {
        isDescending: false,
        pageNumber: 0,
        perPage: 0,
        searchText: '',
        sortingOn: '',
    },
    status: 'active',
    loading: 'idle',
    error: null,
    visits: []
};
