import { IUnitApiState } from '../types/unitsSlice.types';

export const UNITS_INITIAL_STATE: IUnitApiState = {
    data: {
        list: [],
        totalPages: 0,
        totalRecords: 0,
    },
    pageProps: {
        isDescending: false,
        pageNumber: 0,
        perPage: 0,
        searchText: '',
        sortingOn: '',
    },
    selectedUnit: null,
    unitsList: [],
    unitUsers: [],
    facilityUnit: [],
    loading: 'idle',
    error: null,
};
